import {Directive, ElementRef, forwardRef, HostListener, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DecimalNumberPipe} from "../pipes/number.pipe";

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DecimalCurrencyInputDirective),
  multi: true
};

@Directive({
  selector: 'input[type="text"][decimalCurrencyInput]',
  providers: [DecimalNumberPipe, VALUE_ACCESSOR]
})
export class DecimalCurrencyInputDirective implements ControlValueAccessor {

  regexStr = '^-?[0-9]*(\\.|,)?[0-9]*$';
  @Input() onlyPositiveNumber: boolean = false;

  constructor(private elementRef: ElementRef) {
  }

  writeValue(value: any): void {
    console.log(value);
    if (this.onlyPositiveNumber && value) {
      value = value.toString().replace(/-/g, '');
      value = Number(value);
    }
    this.elementRef.nativeElement.value = this.prepareValue(value);
  }

  onChange(_: any) {
  }

  onTouched(_: any) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('keydown', ['$event']) onKeyPress(event) {
    console.log(event);
    if (event.keyCode < 32) {
      return true;
    } else {
      return new RegExp(this.regexStr).test(event.target.value + event.key);
    }
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {

      this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.replace(/[^0-9,.]/g, '').replace(/\s/g, '');
      event.preventDefault();

    }, 100)
  }

  @HostListener('blur', ['$event'])
  onInput(event: any) {
    let value = event.target.value;
    value = this.prepareValue(value);
    this.onChange(value);
    this.writeValue(value);
  }

  private prepareValue(value: any): string {
    if(value) {
      value = value.toString().replace(/,/g, ".");
    }
    value = value || value === 0 ? parseFloat(value).toFixed(2) : null;
    return isNaN(value) ? "0" : value;
  }

}
