import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../auth.service';
import { NavigationService } from 'src/app/shared/navigation.service';
import { Language } from '../language';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE } from '../constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountService } from 'src/app/account/shared/account.service';
import { CookieService } from 'src/app/shared/cookie.service';
declare const $: any;

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css']
})
export class TopNavbarComponent implements OnInit {
  public languages = Language.getAvailableLanguages();
  public currentLanguage: any;

  @Input()
  title: string;

  constructor(private _authService: AuthService, private _navigation: NavigationService, private _translate: TranslateService,
    private _jwtHelper: JwtHelperService, private _accountService: AccountService, private _cookieService: CookieService) {
    this.currentLanguage = Language.fromLocale(this._translate.currentLang) || Language.fromLocale(this._translate.getDefaultLang());
  }

  ngOnInit() {
  }

  logout() {
    // tslint:disable-next-line: deprecation
    event.preventDefault();
    this._authService.disconnect();
    this._cookieService.deleteCookie('clientCryptCode');
    this._navigation.login().go();
  }

  changeLanguage(language: Language) {
    this.currentLanguage = language;
    this._translate.use(this.currentLanguage.locale);
    localStorage.setItem('locale', this.currentLanguage.locale);
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const _tokenData = this._jwtHelper.decodeToken(accessToken).data;
    this._accountService.updateClientLanguage(_tokenData.id, this.currentLanguage.locale).subscribe();
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

}
