import { Client } from './Client';
import { RequestedDevice } from './RequestedDevice';
import { RequestedPrinterType } from './RequestedPrinterType';
import { RequestedUps } from './RequestedUps';
import { RequestedCable } from './RequestedCable';
import { PriceList } from './PriceList';
import { RequestedRouterType } from './RequestedRouterType';
import { RequestedSoftwareType } from './RequestedSoftwareType';
import { Printer } from './Printer';
import { DeviceUser } from './DeviceUser';
import { Router } from './Router';
import { EventOffer } from './EventOffer';
import { PrintersCategories } from './PrintersCategories';
import { OnlineEvent } from './OnlineEvent';

export class Event {
    public id: number;
    public eventName: string;
    public startDate: string;
    public endDate: string;
    public startTime: string;
    public endTime: string;
    public pickUpDate: string;
    public returnDate: string;
    public pickUpTime: string;
    public returnTime: string;
    public createdAt: string;
    public status: number;
    public language: string;
    public latitude: string;
    public longitude: string;
    public useStock: boolean;
    public clientUser: Client = new Client();
    public requestedPrinterTypes: RequestedPrinterType[] = [];
    public requestedUps: RequestedUps[] = [];
    public requestedCable: RequestedCable[] = [];
    public requestedDevices: RequestedDevice[] = [];
    public priceLists: PriceList[] = [];
    public requestedRouterTypes: RequestedRouterType[] = [];
    public requestedSoftwareTypes: RequestedSoftwareType[] = [];
    public printers: Printer[] = [];
    public deviceUsers: DeviceUser[] = [];
    public routers: Router[] = [];
    public printersCategories: PrintersCategories[] = [];
    public eventOffer: EventOffer[] = [];
    public onlineEvent: OnlineEvent;
    public versionLetter: string;
    public offerId: string;

    static fromDto(e): Event {
        const event = new Event();

        if (!e) {
            return null;
        }
        event.id = e.id;
        event.eventName = e.eventName;
        event.startDate = e.startDate;
        event.endDate = e.endDate;
        event.pickUpDate = e.pickUpDate;
        event.pickUpTime = e.pickUpTime;
        event.returnDate = e.returnDate;
        event.returnTime = e.returnTime;
        event.createdAt = e.createdAt;
        event.status = e.status;
        event.language = e.language;
        event.clientUser = e.clientUser;
        event.eventOffer = e.eventOffer;
        event.latitude = e.latitude;
        event.longitude = e.longitude;
        event.requestedPrinterTypes = e.requestedPrinterTypes;
        event.requestedUps = e.requestedUps;
        event.requestedCable = e.requestedCable;
        event.requestedDevices = e.requestedDevices;
        event.priceLists = e.priceLists;
        event.requestedRouterTypes = e.requestedRouterTypes;
        event.requestedSoftwareTypes = e.requestedSoftwareTypes;
        event.printers = e.printers;
        event.deviceUsers = e.deviceUsers;
        event.routers = e.routers;
        event.versionLetter = e.versionLetter;
        event.offerId = e.offerId;
        if (e.printersCategories) {
            event.printersCategories = e.printersCategories.map(printerCategory => PrintersCategories.fromDto(printerCategory));
        }
        if (e.onlineEvent) {
            event.onlineEvent = OnlineEvent.fromDto(e.onlineEvent[0]);
        }

        return event;
    }
}
