import { Event } from './Event';
import { PrinterType } from './PrinterType';

export class Printer {
    public id: number;
    public name: string;
    public identifier: string;
    public status: number;
    public events: Event [] = [];
    public printerType: PrinterType;

    static fromDto(p): Printer {
        const printer = new Printer();

        if (!p) {
            return null;
        }

        printer.id = p.id;
        printer.name = p.name;
        printer.identifier = p.identifier;
        printer.status = p.status;
        printer.events = p.events;
        printer.printerType = p.printerType;

        return p;
    }
}
