import { Product } from './Product';
import { PriceList } from './PriceList';
import { PrintersCategories } from './PrintersCategories';

export class ProductCategory {
    public id: number;
    public name: string;
    public status: number;
    public products: Product[];
    public priceList: PriceList;
    public itemOrder: number;
    public printersCategories: PrintersCategories[];

    static fromDto(p): ProductCategory {
        const productCategory = new ProductCategory();
        if (!p) {
            return null;
        }
        productCategory.id = p.id;
        productCategory.name = p.name;
        productCategory.status = p.status;
        if (p.products) {
            productCategory.products = p.products.map(product => Product.fromDto(product));
        }
        productCategory.priceList = PriceList.fromDto(p.priceList);
        productCategory.itemOrder = p.itemOrder;
        if (p.printersCategories) {
            productCategory.printersCategories = p.printersCategories.map(printerCategory => PrintersCategories.fromDto(printerCategory));
        }
        return productCategory;
    }
}
