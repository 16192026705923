<div class="container">
  <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-6 mx-auto">
          <div class="text-center mt-5 mb-3">
              <img class="logo" src="/assets/famulus_logo_circle.png" alt="Famulus" style="height: 100px; width: auto;">
          </div>
          <div class="card card-signin">
              <div class="card-body">
                  <div class="card-title text-center">
                      <h2>{{title}}</h2>
                  </div>
                  <div class="success-msg" *ngIf="isCompleted">
                    {{message}}
                  </div>
                  <div class="error-msg" *ngIf="hasError">
                    {{message}}
                  </div>
              </div>
              <div class="text-center mt-4 pl-4 pr-4" *ngIf="isCompleted">
                <a href="/login" class="btn btn-primary btn-signin btn-block btn-sm"
                    type="button">Login page</a>
              </div>
              <p class="text-muted text-center mt-5" style="margin-bottom: 0px;"><small>Copyright RR-Solutions  {{currentDate | date: 'yyyy'}}</small></p>
          </div>
      </div>
  </div>
</div>
