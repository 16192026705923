import { DeviceUser } from './DeviceUser';

export class DeviceUserType {
    public id: number;
    public name: string;
    public status: number;
    public deviceUsers: DeviceUser[] = [];

    static fromDto(d): DeviceUserType {
        const deviceUserType = new DeviceUserType();

        if (!d) {
            return null;
        }

        deviceUserType.id = d.id;
        deviceUserType.name = d.name;
        deviceUserType.status = d.status;
        deviceUserType.deviceUsers = d.deviceUsers;

        return d;
    }
}
