import { Component, OnInit, ViewChild } from '@angular/core';
import { EventService } from '../../shared/event.service';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { Printer } from 'src/app/shared/models/Printer';
import { PrintersCategories } from 'src/app/shared/models/PrintersCategories';
import { PriceList } from 'src/app/shared/models/PriceList';
import { Event } from 'src/app/shared/models/Event';
import { forkJoin } from 'rxjs';
import { PriceListService } from 'src/app/price-list/shared/price-list.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


export class PriceListDeviceUser {
  public deviceUser: DeviceUser;
  public printerCategory: PrinterCategory[] = [];
}

export class PrinterCategory {
  public productCategory: ProductCategory;
  public printerItems: PrinterItem[] = [];
}

export class PrinterItem {
  public id: number;
  public printer: Printer;
}

@Component({
  selector: 'app-assign-printer-product-category',
  templateUrl: './assign-printer-product-category.component.html',
  styleUrls: ['./assign-printer-product-category.component.css']
})
export class AssignPrinterProductCategoryComponent implements OnInit {

  public event: Event = new Event();
  public deviceUsersPriceList: PriceListDeviceUser[] = [];
  public deviceUsersByTypePriceList: PriceListDeviceUser[] = [];
  public berichtDeviceUsersByTypePriceList: PriceListDeviceUser;
  public tableDeviceUsersPriceList: any;
  public tableDeviceUsersTypePriceList: any;
  public loadingContent = true;

  private _loading = false;

  @ViewChild('successModal', { static: true })
  successModal;

  constructor(private _eventService: EventService, private _priceListsService: PriceListService, private _toastr: ToastrService,
    private _translate: TranslateService, private _modalService: NgbModal, private _router: Router) { }

  ngOnInit() {
    this._eventService.currentEventObject.subscribe(eventResult => {
      if (eventResult) {
        this.event = eventResult;
        this._eventService.updateCurrentActiveSubPage('assignPrinterCategory');
        new Promise<void>((resolve, reject) => {
          if (!this._loading) {
            this._loading = true;
            this.deviceUsersPriceList = [];
            this.deviceUsersByTypePriceList = [];
            this.tableDeviceUsersPriceList = [];
            this.tableDeviceUsersTypePriceList = [];
            let printersCategoriesArray: PrintersCategories[] = [];
            let priceListsArray: PriceList[] = [];
            const berichtProductCategory: ProductCategory = {
              id: null,
              name: this._translate.instant('assign-category-printer.report'),
              status: 1,
              products: [],
              priceList: null,
              itemOrder: 0,
              printersCategories: undefined
            };
            forkJoin([
              this._eventService.getPrintersCategoriesByEventId(this.event.id.toString()),
              this._priceListsService.getPriceListsByEventId(this.event.id.toString())
            ]).subscribe(results => {
              const onlineResult = results[0];
              priceListsArray = results[1];
              priceListsArray.forEach(priceListItem => {
                priceListItem.productCategories.push(ProductCategory.fromDto(berichtProductCategory));
              });
              onlineResult.map(function (result) {
                if (!result.productCategory) {
                  result.productCategory = { ...berichtProductCategory };
                }
              });
              printersCategoriesArray = onlineResult;
              new Promise<void>((resolve2, reject2) => {
                this.event.deviceUsers.forEach(deviceUser => {
                  if (deviceUser.priceList) {
                    const deviceUserPriceListId = deviceUser.priceList.id.toString();
                    const priceList = priceListsArray.filter(pl => pl.id.toString() === deviceUserPriceListId)[0];
                    const printerCategoryByDeviceTypeExist = this.deviceUsersPriceList.filter(function (item) {
                      if (item.deviceUser.deviceUserType.id === deviceUser.deviceUserType.id) {
                        if (item.deviceUser.priceList.id === priceList.id) {
                          return true;
                        }
                      }
                      return false;
                    })[0];
                    this.deviceUsersPriceList.push(
                      this._getPriceListDeviceUserObject(priceList, deviceUser, printersCategoriesArray)
                    );
                    if (printerCategoryByDeviceTypeExist) {
                      this.deviceUsersByTypePriceList.push(
                        this._getPriceListDeviceUserObject(priceList, deviceUser, printersCategoriesArray)
                      );
                    }
                  }
                });
                resolve2();
              }).then(() => {
                this.tableDeviceUsersPriceList = this.chunk(this.deviceUsersPriceList);
                this.tableDeviceUsersTypePriceList = this.chunk(this.deviceUsersByTypePriceList);
                if (this.deviceUsersPriceList.length > 0) {
                  const firstDeviceUserPriceList = JSON.parse(JSON.stringify(this.deviceUsersPriceList[0]));
                  firstDeviceUserPriceList.printerCategory.splice(0, firstDeviceUserPriceList.printerCategory.length - 1);
                  firstDeviceUserPriceList.printerCategory[0].printerItems = [];
                  this.berichtDeviceUsersByTypePriceList = firstDeviceUserPriceList;
                }
                resolve();
              });
            });
          }
        }).then(() => {
          this.loadingContent = false;
          this._loading = false;
        });
      }
    });
  }

  private _getPriceListDeviceUserObject(priceList: PriceList, deviceUser: DeviceUser, printersCategoriesArray: PrintersCategories[]) {
    const priceListDeviceUser = new PriceListDeviceUser();
    priceListDeviceUser.deviceUser = deviceUser;
    priceList.productCategories.forEach(category => {
      const printerCategory = new PrinterCategory();
      printerCategory.productCategory = ProductCategory.fromDto(category);
      const objectsToAdd = [];
      printersCategoriesArray.forEach(item => {
        if (item.productCategory) {
          if (item.deviceUser.id === deviceUser.id && item.productCategory.id === category.id) {
            objectsToAdd.push(item);
          }
        } else {
          if (item.deviceUser.id === deviceUser.id) {
            objectsToAdd.push(item);
          }
        }
      });
      if (objectsToAdd.length > 0) {
        objectsToAdd.forEach(object => {
          printerCategory.printerItems.push({
            id: object.id,
            printer: object.printer
          });
        });
      }
      priceListDeviceUser.printerCategory.push(printerCategory);
    });

    return priceListDeviceUser;
  }

  onCheckBoxChange(result: any) {
    const configPrinters = this.event.printers;
    this.deviceUsersPriceList.filter(function (value) {
      if (value.deviceUser.id === result.deviceUserId) {
        const printerCategory = value.printerCategory.filter(category => category.productCategory.id === result.productCategoryId)[0];
        const choosenPrinter = printerCategory.printerItems.filter(printerItem => printerItem.printer.id === result.printerId)[0];
        if (!choosenPrinter) {
          printerCategory.printerItems.push({
            id: undefined,
            printer: configPrinters.filter(printer => printer.id === result.printerId)[0]
          });
        } else {
          const index = printerCategory.printerItems.indexOf(choosenPrinter);
          printerCategory.printerItems.splice(index, 1);
        }
      }
    });
  }

  onCancelButtonClick() {
    // this.notifier.next(this.deviceUsers);
  }

  saveSettings() {
    const printersCategoriesArray = [];
    let missingElementCounter = 0;
    console.log('this.deviceUsersPriceList',this.deviceUsersPriceList);
    this.deviceUsersPriceList.forEach(element => {
      element.printerCategory.forEach(printerCategory => {
        if (printerCategory.printerItems.length === 0) {
          if ((element.deviceUser.deviceUserType.id !== 3) || (printerCategory.productCategory.id === null)) {
            // if "is not main device" or "if is main device and bericht"
            missingElementCounter++;
          }
        }
        printerCategory.printerItems.forEach(printerItem => {
          printersCategoriesArray.push({
            id: undefined,
            event: this.event.id,
            deviceUser: element.deviceUser.id,
            printer: printerItem.printer.id,
            productCategory: printerCategory.productCategory.id
          });
        });
      });
    });
    if (missingElementCounter > 0) {
      console.log('ERROR');
      this._toastr.error(this._translate.instant('assign-category-printer.missingErrorMsg'));
      return;
    }
    if (printersCategoriesArray.length > 0) {
      this._eventService.insertPrintersCategories(printersCategoriesArray, this.event.id.toString()).subscribe(result => {
        this._toastr.success(this._translate.instant('assign-category-printer.successMsg'));
        this._eventService.updateWarningMissingCategoryPrinterAssign(false);
      });
    }
  }

  chunk(array) {
    const chunked_arr = [[], []];
    let index = 0;
    while (index < array.length) {
      chunked_arr[(index % 2 === 0 ? 0 : 1)].push(array[index]);
      index++;
    }
    return chunked_arr;
  }

}
