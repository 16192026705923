<div class="row">
  <div class="col">
    <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
      <h1 class="display-4">{{'event.details.overviewDevices' | translate}}</h1>
      <p class="lead">
        {{'event.details.overviewDevicesDesc' | translate}}
      </p>
      <hr class="my-4">
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12 col-lg-6">
    <div class="card">
      <div class="card-header">
        {{'event.details.requestedHardware' | translate}}
      </div>
      <div class="card-body">
        <table class="table ">
          <thead>
            <tr>
              <th>{{'event.details.product' | translate}}</th>
              <th class="text-center ">{{'event.details.quantity' | translate}}</th>
              <th class="text-center ">{{'event.details.ownHardware' | translate}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{'event.details.phones' | translate}}</td>
              <td class="text-center "><span
                  class="badge badge-primary badge-pill ">{{this.requestedPhoneHardware}}</span>
              </td>
              <td class="text-center "><span
                  class="badge badge-primary badge-pill">{{this.requestedPhoneSoftware-this.requestedPhoneHardware}}</span>
              </td>
            </tr>
            <tr>
              <td>{{'event.details.tablets' | translate}}</td>
              <td class="text-center "><span
                  class="badge badge-primary badge-pill ">{{this.requestedTabletHardware}}</span>
              </td>
              <td class="text-center "><span
                  class="badge badge-primary badge-pill">{{this.requestedTabletSoftware-this.requestedTabletHardware}}</span>
              </td>
            </tr>
            <tr>
              <td>{{'event.details.printers' | translate}}</td>
              <td class="text-center "><span
                  class="badge badge-primary badge-pill ">{{this.requestedPrinterHardware}}</span>
              </td>
              <td class="text-center "><span
                  class="badge badge-primary badge-pill">{{this.requestedPrinterSoftware-this.requestedPrinterHardware}}</span>
              </td>
            </tr>
            <tr>
              <td>{{'event.details.routers' | translate}}</td>
              <td class="text-center "><span
                  class="badge badge-primary badge-pill ">{{getTotalQuantity(event.requestedRouterTypes)}}</span>
              </td>
              <td></td>
            </tr>
            <tr *ngFor="let requestedCable of getGroupByCables(event.requestedCable) ">
              <td>{{'event.details.cable' | translate}} -
                {{requestedCable.cables.cableType.length}}M</td>
              <td class="text-center "><span class="badge badge-primary badge-pill ">{{requestedCable.quantity}}</span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card">
      <div class="card-header">
        {{'event.details.additionalCosts' | translate}}
      </div>
      <div class="card-body">
        <table class="table ">
          <thead>
            <tr>
              <th class="text-center ">{{'event.details.additionalCostName' | translate}}</th>
              <th class="text-center ">{{'event.details.additionalCostQuantity' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let additionalCost of additionalCostsArray">
            <tr>
              <td>{{additionalCost.title}}</td>
              <td class="text-center">
                <span class="badge badge-primary badge-pill">{{additionalCost.quantity}}</span>
              </td>
            </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
