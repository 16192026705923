<div class="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">{{'price-list.manage.name' | translate}}</th>
        <th scope="col">{{'price-list.manage.extraInfo' | translate}}</th>
        <th scope="col">{{'price-list.manage.price' | translate}}</th>
        <th scope="col" class="text-center">{{'price-list.manage.position' | translate}}</th>
        <th scope="col" class="text-center">{{'price-list.manage.displayed' | translate}}</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let option of product.productOptions; first as isFirst; last as isLast">
        <ng-container *ngIf="choosenEditProductOption.id === option.id; else elseTemplate">
          <td colspan="6">
            <app-product-option-update-form [productOption]="option" [product]="product"
              (emitCancelProductOptionEditMood)="getCancelProductOptionEditMood()"></app-product-option-update-form>
          </td>
        </ng-container>
        <ng-template #elseTemplate>
          <td>{{option.name}}</td>
          <td>{{option.extraInfo}}</td>
          <td>{{option.price | currency: '€ '}}</td>
          <td class="text-center"><i class="fa fa-arrows-alt-v"></i>{{option.itemOrder}}</td>
          <td class="text-center">
            <ng-container *ngIf="option.status == 1; else disabledTemplate">
              <i style="color: green; cursor: pointer;" class="fas fa-check"
                (click)="changeProductOptionStatus(option, 0)"></i>
            </ng-container>
            <ng-template #disabledTemplate>
              <i style="color: red; cursor: pointer;" class="fas fa-times"
                (click)="changeProductOptionStatus(option, 1)"></i>
            </ng-template>
          </td>
          <td class="text-right">
            <div class="btn-group btn-group-sm" role="group" aria-label="Button group with nested dropdown">
              <button type="button" class="btn btn-link" *ngIf="!isFirst" placement="top"
                [ngbTooltip]="'price-list.manage.moveUp' | translate"
                (click)="changeProductOptionPosition(option.id, option.itemOrder-1)" [disabled]="editProductOption"><i
                  class="fa fa-angle-up"></i></button>
              <button type="button" class="btn btn-link" *ngIf="!isLast" placement="top"
                [ngbTooltip]="'price-list.manage.moveDown' | translate"
                (click)="changeProductOptionPosition(option.id, option.itemOrder+1)" [disabled]="editProductOption"><i
                  class="fa fa-angle-down"></i></button>
              <button type="button" class="btn btn-link" placement="top"
                [ngbTooltip]="'price-list.manage.edit' | translate" (click)="enableProductOptionedit(option)"
                [disabled]="editProductOption"><i class="fa fa-edit"></i></button>
              <button type="button" class="btn btn-link" placement="top"
                [ngbTooltip]="'price-list.manage.delete' | translate" (click)="showDeleteProductOptionDialog(option.id)"
                [disabled]="editProductOption"><i class="fa fa-trash"></i></button>
              <button type="button" class="btn btn-link" placement="top"
                [ngbTooltip]="'price-list.manage.duplicate' | translate"
                (click)="showDuplicateProductOptionDialog(option.id)" [disabled]="editProductOption"><i
                  class="fa fa-copy"></i></button>
            </div>
          </td>
        </ng-template>
      </tr>
    </tbody>
  </table>
</div>

<app-confirm-dialog-model #confirmDeleteProductOptionDialog (getResult)="getDeleteProductOptionDecision($event)"
  [modalTitle]="'price-list.manage.deleteProductOptionTitle' | translate"
  [modalContent]="'price-list.manage.deleteProductOptionMsg' | translate"
  [denyText]="'price-list.manage.no' | translate" [acceptText]="'price-list.manage.yes' | translate">
</app-confirm-dialog-model>

<app-confirm-dialog-model #confirmDuplicateProductOptionDialog (getResult)="getDuplicateProductOptionDecision($event)"
  [modalTitle]="'price-list.manage.duplicateProductOptionTitle' | translate"
  [modalContent]="'price-list.manage.duplicateProductOptionMsg' | translate"
  [denyText]="'price-list.manage.no' | translate" [acceptText]="'price-list.manage.yes' | translate">
</app-confirm-dialog-model>