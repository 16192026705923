<ng-container *ngIf="!isMobileMenu(); else elseTemplate">
    <div class="sidebar-wrapper" style="background-color: #57c6f2 !important;">
        <div class="logo">
            <a href="/dashboard" class="simple-text">
                <div class="logo-img">
                    <img src="/assets/famulus_logo.png" />
                </div>
            </a>
        </div>
        <ul class="nav flex-column responsive-nav">
            <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
                <a [routerLink]="[menuItem.path]">
                    <i class="{{menuItem.icon}}"></i>
                    <p>{{menuItem.title | translate}}</p>
                </a>
            </li>
        </ul>
        <div class="fixed-bottom" style="margin-bottom: 15px; width: 260px; text-align: center;">
            <strong>© {{date | date:'yyyy'}} Copyright: <a href="https://rrsolutions.it" target="_blank"
                    style="color: #FFFFFF; text-decoration: underline;">RR-Solutions</a></strong>
        </div>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto list-inline" id="">
            <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
                <a [routerLink]="[menuItem.path]" class="nav-link">
                    <i class="{{menuItem.icon}}"></i>
                    <p>{{menuItem.title | translate}}</p>
                </a>
            </li>
        </ul>
    </div>
</ng-template>
