import { Component, OnInit, ViewChild, enableProdMode  } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { aggregateBy, AggregateDescriptor, GroupDescriptor, process, State } from '@progress/kendo-data-query';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CustomMessageService } from 'src/app/shared/custom-message.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import * as moment from 'moment';
import { StatisticsKendoService } from './statistics-kendo.service';
import { StatisticTurnover } from 'src/app/shared/models/StatisticsTurnover';
import { EventService } from '../../shared/event.service';

@Component({
  selector: 'app-kendodemo-grid',
  templateUrl: './statistics-kendo.component.html',
  styleUrls: ['./statistics-kendo.component.css']
})
export class StatisticsKendoComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public gridData: GridDataResult;
  public chartData: any;
  public statisticTurnoverData: StatisticTurnover;
  public mySelection: string[] = [];
  public pagingSettings = {
    pagerTypes : ['numeric', 'input'],
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: 'bottom',
    pageSize: 20,
    pageSizes: [
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings = {
    multiple: true,
    allowUnsort: true,
  };

  public state: State = {
    skip: 0,
    take: 20,
    group: [{ field: "categoryName" }]
    // Initial filter descriptor
  };


  private _currentLang = 'it';

  public eventId: number;
  public eventStartDate: Date;
  public eventEndDate: Date;
  public allPriceLists: any[];
  public selectedPriceList: any;

  public noOfOrders;
  public grandTotal;

  public selectionDateRange = {
    start: null,
    end: null
  };

  public aggregates: AggregateDescriptor[] = [
    { field: 'amount', aggregate: 'sum' },
    { field: 'quantity', aggregate: 'sum' },
  ];

  public group: GroupDescriptor[] = [
    { field: 'categoryName', aggregates: this.aggregates },
  ];

  public total: any;

  public showChartChecked = true;
  public showStatistic = false;
  public showDeleteDataChecked = false;
  public dialogOpenened = false;

  constructor(private _translate: TranslateService, private _customMessageService: CustomMessageService,
    private messages: MessageService, private _statisticsKendoService: StatisticsKendoService, private _eventService: EventService) {
      _translate.onLangChange.subscribe(event => {
        this._currentLang = event.lang.toLowerCase();
        this._customMessageService.language = this._currentLang;
        this.changeLanguage();
      });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.statisticTurnoverData.products, this.state);
  }

  public ngOnInit(): void {
    enableProdMode();
    this._eventService.updateCurrentActiveSubPage('statistics2');
    this._eventService.currentEventObject.subscribe(eventResult => {
      this.eventId = eventResult.id;
      this.allPriceLists = eventResult.priceLists;
      if (this.allPriceLists.length === 1) {
        this.selectedPriceList = this.allPriceLists[0];
      }
      if (this.allPriceLists.length > 0) {
        let pusheditems = {};
        pusheditems['id'] = null;
        pusheditems['name'] = this._translate.instant('event.statistic.allPriceListsOption');
        pusheditems['createdAt'] = null;
        this.allPriceLists.unshift(pusheditems);
        this.selectedPriceList = this.allPriceLists[0];
      }
      this.eventStartDate = new Date(eventResult.startDate);
      this.eventEndDate = new Date(eventResult.endDate);
      this.selectionDateRange.start = new Date(eventResult.startDate);
      this.selectionDateRange.end = new Date(eventResult.endDate);

      this._setGridData();
    });
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._currentLang = event.lang.toLowerCase();
      this.changeLanguage();
    });
  }

  private _setGridData() {
    console.log('eventId', this.eventId);
    console.log('priceListId', this.selectedPriceList.id);
    console.log('startDate', moment(this.selectionDateRange.start).format('DD-MM-YYYY'));
    console.log('endDate', moment(this.selectionDateRange.end).format('DD-MM-YYYY'));

    this.showStatistic = false;
    this._statisticsKendoService.getTurnoverData(
      this.eventId,
      this.selectedPriceList.id,
      moment(this.selectionDateRange.start).format('DD-MM-YYYY'),
      moment(this.selectionDateRange.end).format('DD-MM-YYYY'),
    ).subscribe(result => {
      result.noOfOrders = parseInt(result.noOfOrders.toString());
      result.products.forEach(product => {
        product.quantity = parseInt(product.quantity.toString());
      });
      console.log('result:', result);
      this.statisticTurnoverData = result;
      if (result) {
        this.noOfOrders = result.noOfOrders;
        this.grandTotal = result.grandTotal;
        this.state.group = [{ field: "categoryName" }];
        this.gridData = process(result.products, this.state);
        this.total = aggregateBy(result.products, this.aggregates);
        this.excelExportData = this.excelExportData.bind(this);
        this.chartData = result.graphByCategory;

        if (this.noOfOrders > 0) {
          this.showStatistic = true;
        }
      }
    },
    (error => {
      console.log('_renderTable_error', error);
    }));
  }

  public excelExportData(): ExcelExportData {
    const excelState = this.state;
    const excelData = process(this.statisticTurnoverData.products, {
      group: this.state.group,
      sort: this.state.sort,
      filter: this.state.filter
    });
    const result: ExcelExportData = {
      data: excelData.data,
      group: excelState.group
    };
    return result;
  }

  public onSearchButtonClick() {
    if (this.showDeleteDataChecked) {
      this.dialogOpenened = true;
    }
    this._setGridData();
  }

  public onDeleteButtonClick() {
    this.dialogOpenened = true;
  }


  public onResetButtonClick() {
    this.selectionDateRange.start = this.eventStartDate;
    this.selectionDateRange.end = this.eventEndDate;
    this.selectedPriceList = this.allPriceLists[0];
    this.showChartChecked = true;
    this.showStatistic = false;
    this.showDeleteDataChecked = false;
  }

  public closeDialog(confirmDelete) {
    this.dialogOpenened = false;
    if (confirmDelete) {
      this._statisticsKendoService.deleteTurnoverData(
        this.eventId
      ).subscribe(result => {
        console.log('Deleted successfully!! Event id: ', this.eventId);
        this.onResetButtonClick();
        this._setGridData();
      });
    }
  }

  public changeLanguage() {
    const svc = <CustomMessageService>this.messages;
    svc.language = this._customMessageService.language;
  }

  public labelContent(e: any): string {
    //console.log('e:', e);
    const strLabelContent = e.dataItem.categoryName +': '+ e.dataItem.amount + ' €';
    return strLabelContent;
  }

  public onGroupChange(group: GroupDescriptor[]): void {
    // set aggregates to the returned GroupDescriptor
    this.group = [];
    group.map((group) => (group.aggregates = this.aggregates));
    this.group = group;
  }

}

