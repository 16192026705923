import { Component, OnInit, ViewChild } from '@angular/core';
import { LOCAL_STORAGE } from 'src/app/core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { AccountService } from 'src/app/account/shared/account.service';
import { Client } from 'src/app/shared/models/Client';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterType } from 'src/app/shared/models/RouterType';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { DeviceType } from 'src/app/shared/models/DeviceType';
import { SoftwareType } from 'src/app/shared/models/SoftwareType';
import { Ups } from 'src/app/shared/models/Ups';
import { Cable } from 'src/app/shared/models/Cable';
import { EventService } from '../shared/event.service';
import { Event } from 'src/app/shared/models/Event';
import { RequestedPrinterType } from 'src/app/shared/models/RequestedPrinterType';
import { RequestedRouterType } from 'src/app/shared/models/RequestedRouterType';
import { RequestedUps } from 'src/app/shared/models/RequestedUps';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { RequestedSoftwareType } from 'src/app/shared/models/RequestedSoftwareType';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/shared/navigation.service';

export class CableSizes {
  one = 0;
  five = 0;
  ten = 0;
  fifteen = 0;
  twenty = 0;
  thirty = 0;
  fifty = 0;
}

export class PriceCalculation {
  public days: number;
  public countryId: number;
  public requestedPrinterTypes: RequestedPrinterType[];
  public requestedUps: RequestedUps[];
  public requestedCables: RequestedCable[];
  public requestedDeviceTypes: RequestedDevice[];
  public requestedRouterTypes: RequestedRouterType[];
  public requestedSoftwareTypes: RequestedSoftwareType[];
}

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {

  public event: Event = new Event();
  public totalPrice = 0.00;
  public eventName = '';
  public startDate = this._fromModel(moment());
  public endDate = this._fromModel(moment());
  public pickUpDate = this._fromModel(moment().subtract(1, 'day'));
  public returnDate = this._fromModel(moment().add(1, 'day'));
  public pickUpTime = '09:00';
  public returnTime = '09:00';
  public waiterQuantity = 0;
  public cashRegisterQuantity = 0;
  public mainDeviceQuantity = 0;
  public phoneQuantity = 0;
  public tabletQuantity = 0;
  public printerQuantity = 0;
  public routerQuantity = 0;
  public upsQuantity = 0;
  public printerSoftwareQuantity = 0;
  public cableQuantity: CableSizes = new CableSizes();
  public eventTitleError = '';
  public startDateError = '';
  public endDateError = '';
  public pickUpError = '';
  public returnDateError = '';
  public softwareError = '';

  private _routerTypesArray: RouterType[];
  private _printerTypesArray: PrinterType[];
  private _deviceTypesArray: DeviceType[];
  private _softwareTypesArray: SoftwareType[];
  private _upsArray: Ups[];
  private _cableArray: Cable[];
  private _currentClient: Client = new Client();

  // missingCompanyModal

  @ViewChild('missingCompanyModal', { static: true })
  missingCompanyModal;

  constructor(public jwtHelper: JwtHelperService, private _accountService: AccountService, private _eventService: EventService,
    private _toastrService: ToastrService, private _translateService: TranslateService, private _navigationSerivce: NavigationService,
    private modalService: NgbModal) { }

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this.jwtHelper.decodeToken(accessToken).data;
    this._accountService.getSpecificClientById(tokenData.id).subscribe(result => {
      console.log('result', result);
      this._currentClient = result;
      if (this._currentClient.company == null) {
        this.modalService.open(this.missingCompanyModal, { centered: true });
      }
    }, error => {
      console.log(error);
    });
    this._eventService.getAllRouterTypes().subscribe(result => { this._routerTypesArray = result; }, error => { console.error(error); });
    this._eventService.getAllPrinterTypes().subscribe(result => { this._printerTypesArray = result; }, error => { console.error(error); });
    this._eventService.getAllDeviceTypes().subscribe(result => { this._deviceTypesArray = result; }, error => { console.error(error); });
    this._eventService.getAllSoftwareTypes().subscribe(result => { this._softwareTypesArray = result; },
      error => { console.error(error); });
    this._eventService.getAllUps().subscribe(result => { this._upsArray = result; }, error => { console.error(error); });
    this._eventService.getAllCables().subscribe(result => { this._cableArray = result; }, error => { console.error(error); });
  }

  onStartDateChange(date) {
    this.pickUpDate = this._fromModel(this._toModel(date).subtract(1, 'day'));
    this.endDate = this._fromModel(this._toModel(date).add(1, 'day'));
    this.returnDate = this._fromModel(this._toModel(date).add(2, 'day'));
    this.updateEventValues();
  }

  onEndDateChange(date) {
    this.returnDate = this._fromModel(this._toModel(date).add(1, 'day'));
    this.updateEventValues();
  }

  public validateNewEventRequest(): boolean {
    this.eventTitleError = '';
    this.startDateError = '';
    this.endDateError = '';
    this.pickUpError = '';
    this.returnDateError = '';
    this.softwareError = '';
    if (this.eventName === '' || this.eventName === undefined) {
      this.eventTitleError = 'event.request.missingEventNameError';
      return false;
    }
    const startDate = moment(this.startDate);
    let endDate = moment(this.endDate);
    const pickUpDate = moment(this.pickUpDate);
    const returnDate = moment(this.returnDate);
    if (startDate > endDate) {
      // this.startDateError = 'event.request.startDateError';
      endDate = startDate;
      return false;
    }
    if (pickUpDate > returnDate) {
      this.pickUpError = 'event.request.pickUpDateAfterReturnDateError';
      return false;
    }
    if (pickUpDate > startDate) {
      this.pickUpError = 'event.request.pickUpDateAfterStartDateError';
      return false;
    }
    if (returnDate < endDate) {
      this.returnDateError = 'event.request.returnDateAfterEndDateError';
      return false;
    }

    if ((this.waiterQuantity === 0 && this.cashRegisterQuantity === 0 && this.printerQuantity === 0) ||
      (this.waiterQuantity >= 1 && this.cashRegisterQuantity === 0 && this.printerQuantity === 0) ||
      (this.waiterQuantity === 0 && this.cashRegisterQuantity >= 1 && this.printerQuantity === 0) ||
      (this.waiterQuantity === 0 && this.cashRegisterQuantity === 0 && this.printerQuantity >= 1) ||
      (this.waiterQuantity >= 1 && this.cashRegisterQuantity >= 1 && this.printerQuantity === 0)) {
      this.softwareError = 'event.request.minSoftwareRequirementError';
      return false;
    }

    return true;
  }

  public updateEventValues() {
    this.eventTitleError = '';
    this.event = new Event();
    this.event.eventName = this.eventName;
    this.event.startDate = this._toModel(this.startDate).format('YYYY-MM-DD');
    this.event.endDate = this._toModel(this.endDate).format('YYYY-MM-DD');
    this.event.pickUpDate = this._toModel(this.pickUpDate).format('YYYY-MM-DD');
    this.event.returnDate = this._toModel(this.returnDate).format('YYYY-MM-DD');

    this.event.pickUpTime = this.pickUpTime;
    this.event.returnTime = this.returnTime;

    if (this.waiterQuantity > 0) {
      this._addNewSoftwareType(this.waiterQuantity, 1);
    }
    if (this.cashRegisterQuantity > 0) {
      this._addNewSoftwareType(this.cashRegisterQuantity, 2);
    }
    if (this.mainDeviceQuantity > 0) {
      this._addNewSoftwareType(this.mainDeviceQuantity, 3);
    }
    if (this.printerSoftwareQuantity > 0) {
      this._addNewSoftwareType(this.printerSoftwareQuantity, 4);
    }
    if (this.phoneQuantity > 0) {
      this._addNewDeviceType(this.phoneQuantity, 1);
    }
    if (this.tabletQuantity > 0) {
      this._addNewDeviceType(this.tabletQuantity, 2);
    }

    if (this.printerQuantity > 0) {
      const printerRequest = new RequestedPrinterType();
      printerRequest.quantity = this.printerQuantity;
      printerRequest.printerType = this._printerTypesArray.filter(printerType => printerType.id === 1)[0];
      this.event.requestedPrinterTypes.push(printerRequest);
    }

    if (this.routerQuantity > 0) {
      const routerRequest = new RequestedRouterType();
      routerRequest.quantity = this.routerQuantity;
      routerRequest.routerType = this._routerTypesArray.filter(routerType => routerType.id === 1)[0];
      this.event.requestedRouterTypes.push(routerRequest);
    }

    if (this.upsQuantity > 0) {
      const upsRequest = new RequestedUps();
      upsRequest.quantity = this.upsQuantity;
      upsRequest.ups = this._upsArray.filter(ups => ups.id === 1)[0];
      this.event.requestedUps.push(upsRequest);
    }

    this._addNewCables();
    this.priceCalculation();
  }

  public submitNewEventRequest() {
    if (this.validateNewEventRequest() === false) {
      return;
    }
    this.event.clientUser = this._currentClient;
    this._eventService.insertEventRequest(this.event).subscribe(result => {
      this._toastrService.success(this._translateService.instant('event.request.eventSuccessRequest'));
      this._navigationSerivce.myEvents().go();
    }, error => {
      console.log(error);
    });
  }

  private priceCalculation() {
    const duration = moment.duration(moment(this.event.endDate, 'YYYY-MM-DD').diff(moment(this.event.startDate, 'YYYY-MM-DD')));
    const priceRequest: PriceCalculation = new PriceCalculation();
    priceRequest.days = duration.asDays() + 1;
    priceRequest.countryId = this._currentClient.company.country.id;
    priceRequest.requestedCables = this.event.requestedCable;
    priceRequest.requestedDeviceTypes = this.event.requestedDevices;
    priceRequest.requestedPrinterTypes = this.event.requestedPrinterTypes;
    priceRequest.requestedRouterTypes = this.event.requestedRouterTypes;
    priceRequest.requestedSoftwareTypes = this.event.requestedSoftwareTypes;
    priceRequest.requestedUps = this.event.requestedUps;
    this._eventService.calculatePrice(priceRequest).subscribe(result => {
      this.totalPrice = 0.00;
      this.totalPrice += result.totalPrinterPrice;
      this.totalPrice += result.totalUpsTypePrice;
      this.totalPrice += result.totalDeviceTypePrice;
      this.totalPrice += result.totalRouterTypePrice;
      this.totalPrice += result.totalSoftwareTypePrice;
    }, error => {
      console.log(error);
    });
  }

  private _addNewCables() {
    let counter = 1;
    for (const key in this.cableQuantity) {
      if (this.cableQuantity.hasOwnProperty(key)) {
        const quantity = this.cableQuantity[key];
        if (quantity > 0) {
          const cableRequest = new RequestedCable();
          cableRequest.quantity = quantity;
          cableRequest.cables = this._cableArray.filter(cable => cable.id === counter)[0];
          this.event.requestedCable.push(cableRequest);
        }
        counter++;
      }
    }
  }

  private _addNewSoftwareType(quantity: number, softwareTypeId: number) {
    const softwareTypeRequest = new RequestedSoftwareType();
    softwareTypeRequest.quantity = quantity;
    softwareTypeRequest.softwareType = this._softwareTypesArray.filter(softwareType => softwareType.id === softwareTypeId)[0];
    this.event.requestedSoftwareTypes.push(softwareTypeRequest);
  }

  private _addNewDeviceType(quantity: number, deviceTypeId: number) {
    const deviceRequest = new RequestedDevice();
    deviceRequest.quantity = quantity;
    deviceRequest.deviceTypes = this._deviceTypesArray.filter(deviceType => deviceType.id === deviceTypeId)[0];
    this.event.requestedDevices.push(deviceRequest);
  }

  private _fromModel(date: moment.Moment): NgbDateStruct {
    if (!date) {
      return null;
    }
    return { day: date.date(), month: date.month() + 1, year: date.year() };
  }

  private _toModel(date: NgbDateStruct): moment.Moment {
    if (!date) {
      return null;
    }
    return moment(date.day + '-' + date.month + '-' + date.year, 'DD-MM-YYYY');
  }

}
