import { Component, OnInit } from '@angular/core';
import { LOCAL_STORAGE } from 'src/app/core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EventService } from '../shared/event.service';
import { Event } from 'src/app/shared/models/Event';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { EventOffer } from 'src/app/shared/models/EventOffer';

class EventPreview {
  public id: string;
  public eventName: string;
  public startDate: string;
  public endDate: string;
  public pickUpDate: string;
  public returnDate: string;
  public status: number;
  public eventType: string;
  public createdAt: string;
  public offerLongId?: string;
  public offerVersionLetter?: string;
  public hasOnlineEvent: false | boolean;
  public isEventFinish: false | boolean;
}

@Component({
  selector: 'app-my-events',
  templateUrl: './my-events.component.html',
  styleUrls: ['./my-events.component.css']
})
export class MyEventsComponent implements OnInit {

  public clientEvents: Event[];
  public events = Array<[number, [EventPreview]]>();
  public years: Array<number> = [];
  constructor(public jwtHelper: JwtHelperService, private _eventService: EventService, private _toastr: ToastrService,
    private _translate: TranslateService) { }

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this.jwtHelper.decodeToken(accessToken).data;
    console.log(tokenData);
    forkJoin([
      this._eventService.getEventsForClient(tokenData.id),
      this._eventService.getEventOffersForClient(tokenData.id),
    ]).subscribe(result => {
      console.log('event:', result[0]);
      console.log('eventOffer:', result[1]);
      this._sortDataEvent(result[0]);
      this._sortDataEventOffers(result[1]);
      const years = this._getAllEventYears(result[0], result[1], 'desc');

      years.forEach(year => {
        result[0].forEach(element => {
          if (year === new Date(element.endDate).getFullYear()) {
            this._addEventPreviewToArray(element, 'event');
          }
        });
        result[1].forEach(element => {
          if (year === new Date(element.endDate).getFullYear()) {
            this._addEventPreviewToArray(element, 'offer');
          }
        });
      });
    });
  }

  private _addEventPreviewToArray(element: any, eventType: string) {
    const eventPreview = new EventPreview();
    const startYear = moment(element.startDate).format('YYYY');
    if (!this.events[startYear]) {
      this.events[startYear] = [];
    }
    if (eventType === 'event') {
      element.endDate = moment(element.endDate).subtract(1, 'day').format('YYYY-MM-DD');
      if (element.onlineEvent) {
        eventPreview.hasOnlineEvent = element.onlineEvent.onlinePublish;
      }
    }
    eventPreview.id = element.id;
    eventPreview.eventName = element.eventName;
    eventPreview.startDate = element.startDate;
    eventPreview.endDate = element.endDate;
    eventPreview.pickUpDate = element.pickUpDate;
    eventPreview.returnDate = element.returnDate;
    eventPreview.status = element.status;
    eventPreview.eventType = eventType;
    eventPreview.createdAt = element.createdAt;
    if (eventType === 'offer') {
      eventPreview.offerLongId = element.offerId;
      eventPreview.offerVersionLetter = element.versionLetter;
    } else {
      console.log('element:', element);
      console.log('id', element.id);
      eventPreview.offerLongId = element.offerId;
      eventPreview.offerVersionLetter = element.versionLetter;
      const momentEndDate = moment(element.endDate).set({hour:0,minute:0,second:0,millisecond:0});;
      const today = moment().set({hour:0,minute:0,second:0,millisecond:0});;
      console.log('momentEndDate', momentEndDate);
      console.log('today', today);
      if (element.status === 2) {
        if (today > momentEndDate) {
          eventPreview.isEventFinish = true;
        }
      }
    }

    this.events[startYear].push(eventPreview);

    if (this.years.indexOf(parseFloat(startYear)) === -1) {
      this.years.push(parseFloat(startYear));
    }

  }

  _sortDataEvent(data: Event[]) {
    return data.sort((a, b) => {
      return <any>new Date(b.endDate) - <any>new Date(a.endDate);
    });
  }

  _sortDataEventOffers(data: EventOffer[]) {
    return data.sort((a, b) => {
      return <any>new Date(b.endDate) - <any>new Date(a.endDate);
    });
  }

  _getAllEventYears(rsE: Event[], rsEO: EventOffer[], sort: string) {
    const years: number[] = [];
    const types: string[] = ['event', 'offer'];
    types.forEach(type => {
      const rs = (type === 'event' ? rsE : rsEO);
      rs.forEach(rec => {
        const endYear = new Date(rec.endDate).getFullYear();
        if (years.indexOf(endYear) === -1) {
          years.push(endYear);
        }
      });
    });
    if (sort.toLowerCase() === 'desc') {
      return years.sort((a, b) => {
        return <number>b - a;
      });
    } else {
      return years.sort((a, b) => {
        return <number>a - b;
      });
    }

  }

  downloadOfferPdf(offerId: string, versionLetter: string, createdAt: string) {
    console.log(createdAt);
    this._eventService.downloadEventOfferPdf(offerId, versionLetter).subscribe(result => {
      const offerFileName = this._translate.instant('event.myEvents.eventOfferNr') +
        moment(createdAt, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD') + '_' + versionLetter;
      saveAs(result, offerFileName + '.pdf');
    }, error => {
      if (error.status) {
        this._toastr.warning('The PDF is creating. Please wait.');
      }
    });
  }
}
