import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { URL_BASE } from 'src/app/core/constants';
import { HttpUtils } from 'src/app/shared/http-utils';
import { StatisticTurnover } from 'src/app/shared/models/StatisticsTurnover';
import { environment } from 'src/environments/environment';


enum EndpointRoutes {
  STATISTIC_TURNOVER = '/turnOver/get',
  STATISTIC_TURNOVER_DELETEDATA = '/turnOver/delete'
}

@Injectable({
  providedIn: 'root'
})
export class StatisticsKendoService {

  private _headers: any;

  constructor(private _http: HttpClient) {
  }


  public getTurnoverData(eventId: number, priceListId: number, startDate: string, endDate: string): Observable<StatisticTurnover> {
    const headers = HttpUtils.createHeaders();
    let params = new HttpParams();
    params = params.append('eventId', eventId.toString());
    params = params.append('priceListId', priceListId ? priceListId.toString() : '');
    params = params.append('startDate', startDate.toString());
    params = params.append('endDate', endDate.toString());
    return this._http.get<StatisticTurnover>(URL_BASE + EndpointRoutes.STATISTIC_TURNOVER, { headers: headers, params: params }).pipe(
      map(responseData => {
        return responseData
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public deleteTurnoverData(eventId: number): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + EndpointRoutes.STATISTIC_TURNOVER_DELETEDATA, { headers: headers, params: { eventId: eventId }
    })
  }
}
