<div class="container mt-3" id="container">
    <div data-size="A4" id="container-print-deliverypaper">
        <div>
          <div class="row">
              <div class="col" id="title-container">
                  <h2>{{'event.delivery-paper.deliveryPaper' | translate}}</h2>
              </div>
          </div>
          <div class="row mt-3">
              <div class="col-6">
                  <div class="card border" style="height: 150px;">
                      <div class="card-body">
                          <strong class="text-uppercase">{{'event.delivery-paper.sender' | translate}}</strong>
                          <div class="card-text" [innerHTML]="'event.delivery-paper.addressSender' | translate">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-6">
                  <div class="card border" style="height: 150px;">
                      <div class="card-body">
                          <strong class="text-uppercase">{{'event.delivery-paper.reciever' | translate}}</strong>
                          <div class="card-text">
                              {{event.clientUser.firstName}} {{event.clientUser.lastName}}<br />
                              {{event.clientUser.company.name}}
                              <br /> {{event.clientUser.company.address}} <br /> I -
                              {{event.clientUser.company.postalCode}}
                              <br /> {{event.clientUser.company?.village?.name}}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mt-1 ">
              <div class="col">
                  <div class="card border" style="height: 70px;">
                      <div class="card-body">
                          <div class="row" style="margin-top: -5px;">
                              <div class="col">
                                  <strong
                                      class="text-uppercase">{{'event.delivery-paper.eventName' | translate}}:</strong>
                                  <div class="card-text">
                                      {{event.eventName}}
                                  </div>
                              </div>
                              <div class="col">
                                  <strong class="text-uppercase">{{'event.delivery-paper.date' | translate}}:</strong>
                                  <div class="card-text">
                                      {{event.startDate | date:'dd-MM-yyyy'}} / {{event.endDate | date:'dd-MM-yyyy'}}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mt-3">
              <div class="col">
                  <table class="table table-bordered" style="margin-top: -12px">
                      <thead>
                          <tr>
                              <th class="text-uppercase text-center" style="width: 70%; color: #000000;">
                                  {{'event.delivery-paper.productDescription' | translate}}
                              </th>
                              <th class="text-uppercase text-center" style="color: #000000;">
                                  {{'event.delivery-paper.quantity' | translate}}
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          <ng-container *ngFor="let device of event.requestedDevices">
                              <tr>
                                  <ng-container *ngIf="device.deviceTypes.id === 1">
                                      <td>{{'event.delivery-paper.phone' | translate}}</td>
                                      <td class="text-right">{{device.quantity}}</td>
                                  </ng-container>
                              </tr>
                              <tr>
                                  <ng-container *ngIf="device.deviceTypes.id === 1">
                                      <td>{{'event.delivery-paper.phoneCharger' | translate}}</td>
                                      <td class="text-right">{{device.quantity}}</td>
                                  </ng-container>
                              </tr>
                              <tr>
                                  <ng-container *ngIf="device.deviceTypes.id === 2">
                                      <td>{{'event.delivery-paper.tablet' | translate}}</td>
                                      <td class="text-right">{{device.quantity}}</td>
                                  </ng-container>
                              </tr>
                              <tr>
                                  <ng-container *ngIf="device.deviceTypes.id === 2">
                                      <td>{{'event.delivery-paper.tabletCharger' | translate}}</td>
                                      <td class="text-right">{{device.quantity}}</td>
                                  </ng-container>
                              </tr>
                          </ng-container>
                          <ng-container *ngIf="event.routers && event.routers.length > 0">
                              <tr>
                                  <td>{{'event.delivery-paper.routers' | translate}}</td>
                                  <td class="text-right">{{event.routers.length}}</td>
                              </tr>
                              <tr>
                                  <td>{{'event.delivery-paper.routerCharger' | translate}}</td>
                                  <td class="text-right">{{event.routers.length}}</td>
                              </tr>
                          </ng-container>
                          <ng-container *ngIf="event.printers && event.printers.length  > 0">
                              <tr>
                                  <td>{{'event.delivery-paper.printers' | translate}}</td>
                                  <td class="text-right">{{event.printers.length}}</td>
                              </tr>
                              <tr>
                                  <td>{{'event.delivery-paper.printerCharger' | translate}}</td>
                                  <td class="text-right">{{event.printers.length}}</td>
                              </tr>
                          </ng-container>
                          <tr *ngFor="let additionalCost of additionalCostsArray">
                              <td>{{getadditionalCostLabel(additionalCost.name)}}</td>
                              <td class="text-right">{{additionalCost.quantityRequested}}</td>
                          </tr>
                          <tr *ngFor="let cable of cablesAmount">
                              <td>{{'event.delivery-paper.cables' | translate}} {{cable.length}}m</td>
                              <td class="text-right">{{cable.amount}}</td>
                          </tr>
                          <ng-container *ngIf="event.printers && event.printers.length > 0 ">
                              <tr>
                                  <td>{{'event.delivery-paper.paperRolls' | translate}}</td>
                                  <td class="text-right">{{event.printers.length * 2}}</td>
                              </tr>
                          </ng-container>
                      </tbody>
                  </table>
              </div>
          </div>
          <div class="row">
              <div class="col">
                  <table class="table table-bordered">
                      <thead>
                          <th>{{'event.delivery-paper.routerName' | translate}}</th>
                          <th>{{'event.delivery-paper.networkName' | translate}}</th>
                          <th>{{'event.delivery-paper.networkPassword' | translate}}</th>
                          <th>{{'event.delivery-paper.routerType' | translate}}</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let router of event.routers"
                              style="page-break-inside: avoid; page-break-after: auto">
                              <td>{{router.name}}</td>
                              <td>{{router.networkName}}</td>
                              <td>{{router.networkPassword}}</td>
                              <td>{{(router.master === 0 ? 'event.delivery-paper.mainRouter' : 'event.delivery-paper.slaveRouter') | translate}}
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  <table class="table table-bordered">
                      <thead>
                          <th>{{'event.delivery-paper.printerName' | translate}}</th>
                          <th>{{'event.delivery-paper.printerCategory' | translate}}</th>
                          <th>{{'event.delivery-paper.deviceName' | translate}}</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let printerCat of printersCategoriesDeliveryPaper">
                              <td>{{printerCat.printerName}}</td>
                              <td>{{printerCat.categoryName}}</td>
                              <td>{{printerCat.deviceName}}</td>
                          </tr>
                      </tbody>
                  </table>
                  <table class="table table-bordered">
                      <thead>
                          <th>{{'event.delivery-paper.name' | translate}}</th>
                          <th>{{'event.delivery-paper.rule' | translate}}</th>
                          <th>{{'event.delivery-paper.username' | translate}}</th>
                          <th>{{'event.delivery-paper.accessCode' | translate}}</th>
                          <th>{{'event.delivery-paper.lvl1Code' | translate}}</th>
                          <th>{{'event.delivery-paper.deviceName' | translate}}</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let deviceUser of event.deviceUsers"
                              style="page-break-inside: avoid; page-break-after: auto">
                              <td>{{deviceUser.name}}</td>
                              <td>{{deviceUser.deviceUserType.name | deviceTypeTranslation}}</td>
                              <td>{{deviceUser.username}}</td>
                              <td>{{deviceUser.pinCode}}</td>
                              <td>{{deviceUser.lvlOnePin}}</td>
                              <td>{{ (!deviceUser.device) ? 'N/D' : deviceUser.device.name }}</td>
                          </tr>
                      </tbody>
                  </table>
                  <blockquote class="blockquote mt-5">
                      <h4 class="mb-0 mt-0 text-center" [innerHTML]="'event.delivery-paper.warningMessage' | translate">
                      </h4>
                  </blockquote>
                  <blockquote class="blockquote mt-5">
                    <h4 class="mb-0 mt-0 text-center" [innerHTML]="'event.delivery-paper.videoQrCodeMessage' | translate"> </h4>
                    <img *ngIf="this.currentLanguage === 'de'" src="../../../../assets/img/qrcode_famulus_video_de.png" style="display:block; width:100px;height: auto; margin:0 auto;"/>
                    <img *ngIf="this.currentLanguage === 'it'" src="../../../../assets/img/qrcode_famulus_video_it.png" style="display:block; width:100px;height: auto; margin:0 auto;"/>
                </blockquote>
              </div>
          </div>
        </div>
    </div>
</div>
