import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Client } from 'src/app/shared/models/Client';
import { ToastrService } from 'ngx-toastr';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { AuthService } from '../auth.service';
import { NavigationService } from 'src/app/shared/navigation.service';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { TranslateService } from '@ngx-translate/core';
import { Company } from 'src/app/shared/models/Company';
import { Router } from '@angular/router';
import { Village } from 'src/app/shared/models/Village';
import { Country } from 'src/app/shared/models/Country';
import { AccountService } from 'src/app/account/shared/account.service';
import { Survey } from 'src/app/shared/models/Survey';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public registerForm: FormGroup;
  public companyRegisterForm: FormGroup;
  public errorArray = new Array();
  public companyErrorArray = new Array();
  public currentDate = new Date();
  public languageOptions: Option[] = [{ value: 'de', label: 'register.german' },
  { value: 'it', label: 'register.italian' }];
  public titleOptions: Option[] = [
    { value: 'Herr', label: 'register.herr' },
    { value: 'Frau', label: 'register.frau' }
  ];

  public surveys: Survey[] = [];
  private _surveyAnswer : any;

  public currentLanguage: any;
  public choosenVillage: any;
  public companyVillage: Village;
  public choosenProvince: number;
  public choosenRegion: number;
  public choosenSurvey: any;
  public missingVillageErrorMsg = '';
  public missingSurveyErrorMsg = '';
  public villageObjects: Village[] = [];

  public locationGroupClass = "col";
  public surveyGroupClass = "row";

  constructor(private _toastr: ToastrService, private _formBuilder: FormBuilder, private _authService: AuthService,
    private _navigation: NavigationService, private _router: Router, private _translate: TranslateService,
    private _accountService: AccountService
    ) {
    this.registerForm = this._formBuilder.group({
      title: ['Herr', <any>Validators.required],
      firstName: ['', <any>Validators.required],
      lastName: ['', <any>Validators.required],
      firstEmail: ['', [<any>Validators.required, <any>Validators.email]],
      firstPhone: ['', <any>Validators.required],
      newsletter: [false],
      termsConditionsCheck: [false],
      termsConditionsCheckPoint4: [true],
      termsConditionsCheckPoint6: [true],
      termsConditionsCheckPoint9: [true],
      language: ['de', [<any>Validators.required]],
      survey: [0],
      surveyDescription: ['']
    }, {
      validators: [
        this.checkTermsAndConditions('termsConditionsCheck'),
        this.checkTermsAndConditions('termsConditionsCheckPoint4'),
        this.checkTermsAndConditions('termsConditionsCheckPoint6'),
        this.checkTermsAndConditions('termsConditionsCheckPoint9')
      ],
    });
    this.companyRegisterForm = this._formBuilder.group({
      name: ['', <any>Validators.required]
    });
    this.currentLanguage = this._translate.currentLang;
  }

  ngOnInit() {
    if (this._authService.isAuthenticated()) {
      return this._navigation.home().go();
    }
    this.registerForm.get('language').setValue(localStorage.getItem('locale'));
    forkJoin([
      this._accountService.getAllVillagesWithoutToken(),
      this._accountService.getSurveyOptions()
    ]).subscribe(results => {
      this.villageObjects = results[0];
      this.surveys = results[1];
      this.surveys.forEach(item => {
        item.name = this.currentLanguage === 'it' ? item.nameIt : item.nameDe;
      });
    });
  }

  onRegistration() {
    console.log('choosenVillage:', this.choosenVillage);

    if (!this.choosenVillage) {
      this.locationGroupClass = "col border-red";
      this.missingVillageErrorMsg = this._translate.instant('account.errorMsg.missingVillage');
    } else {
      this.missingVillageErrorMsg = null;
      this.locationGroupClass = "col";
    }

    if (this.registerForm.controls["survey"].value === 0) {
      this.surveyGroupClass = "row border-red-two";
      this.missingSurveyErrorMsg = this._translate.instant('account.errorMsg.missingSurvey');
    } else {
      this.missingSurveyErrorMsg = null;
      this.surveyGroupClass = "row";
    }

    if (this.registerForm.valid && this.companyRegisterForm.valid) {

      if((!this.choosenVillage) || (this.registerForm.controls["survey"].value === 0)) {
        return;
      }

      const company = Company.fromDto(this.companyRegisterForm.value);
      const client = Client.fromDto(this.registerForm.value);
      company.status = 1;
      client.company = company;
      client.username = client.firstEmail;
      client.villageId = this.choosenVillage.value;
      client.answer.surveyId = this.registerForm.controls["survey"].value;
      if (this.registerForm.controls["survey"].value === 7) {
        client.answer.description = this.registerForm.controls["surveyDescription"].value;
      } else {
        client.answer.description = "";
      }
      console.log('myClientToSave: ',client);
      this._authService.registerNewClientUser(client).subscribe(result => {

        this.registerForm.reset({ language: client.language });
        this._translate.use(client.language);
        localStorage.setItem('locale', client.language);
        this._router.navigate(['/login'], { queryParams: { action: 'register' } });
        // this._navigation.login({ queryParams: { action: 'register' } }).go();
      }, error => {
        if (error.status === 600) {
          this.errorArray['firstEmail'] = 'register.errorMsg.emailExist';
        }
      });
    }
    if (!this.choosenVillage) {
      this.locationGroupClass = "col border-red";
      this.missingVillageErrorMsg = this._translate.instant('account.errorMsg.missingVillage');
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.registerForm, 'register');
    this.companyErrorArray = ValidationUtils.getFormValidationErrors(this.companyRegisterForm, 'register');
  }

  checkTermsAndConditions(termsConditionsCheckKey: string) {
    return (group: FormGroup) => {
      const termsAndConditionsCheck = group.controls[termsConditionsCheckKey];
      if (!termsAndConditionsCheck.value) {
        return termsAndConditionsCheck.setErrors({ notTerms: true });
      } else {
        return termsAndConditionsCheck.setErrors(null);
      }
    };
  }
}
