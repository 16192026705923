<div class="row">
  <div class="col">
    <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
      <h1 class="display-4">{{'assign-category-printer.title' | translate}}</h1>
      <p class="lead">{{'assign-category-printer.description' | translate}}</p>
      <hr class="my-4">
    </div>
  </div>
</div>
<div [hidden]="!loadingContent">
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div [hidden]="loadingContent">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-6" *ngFor="let deviceUsersPriceListArray of tableDeviceUsersPriceList">
          <table class="table" style="display: block; overflow: auto;" *ngFor="let element of deviceUsersPriceListArray">
            <tbody>
              <tr>
                <td [attr.colspan]="(event.printers.length + 1)" class="border-0">
                  <h6 class="m-3">{{element.deviceUser.name}}</h6>
                </td>
              </tr>
              <ng-container *ngFor="let category of element.printerCategory">
                <ng-container *ngIf="element.deviceUser.deviceUserType.id !== 3 || (element.deviceUser.deviceUserType.id === 3 && !category.productCategory.id)"> <!-- this if exclude berichts-->
                  <tr>
                    <td><div>{{category.productCategory.name}}</div></td>
                    <td>
                      <div class="row">
                        <div *ngFor="let printer of event.printers" style="float:left; padding:3px 5px; margin:3px;">
                          <app-custom-switch-printer [printer]="printer" [deviceUsersPriceList]="element"
                            [printerCategory]="category" (onSwitchChange)="onCheckBoxChange($event)" [eventFinished]="false">
                          </app-custom-switch-printer>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="text-right mt-3">
            <!-- <button type="button" [hidden]="false" class="btn btn-danger btn-sm mr-2" (click)="onCancelButtonClick()"><i
                class="fa fa-times"></i>Cancel</button> -->
            <button type="button" [disabled]="false" class="btn btn-primary btn-sm" (click)="saveSettings()"><i
                class="fa fa-save"></i>
              {{'assign-category-printer.save' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #successModal let-successModal>
  <div class="modal-body">
    <p class="text-center">
      {{'conclutionModal.description' | translate}}
    </p>
    <p class="text-center">
      {{'conclutionModal.description2' | translate}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark"
      (click)="successModal.close()">{{'conclutionModal.closeBtn' | translate}}</button>
  </div>
</ng-template>
