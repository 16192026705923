<ng-container *ngIf="disableDeviceMenuItem; else overviewTemplate">
  <div class="row">
    <div class="col">
      <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
        <h1 class="display-4">{{'event.details.usersAndAccessDatas' | translate}}</h1>
        <p class="lead">
          {{'event.details.missingHardwareDesc' | translate}}
        </p>
        <hr class="my-4">
      </div>
    </div>
  </div>
</ng-container>
<ng-template #overviewTemplate>
  <div class="row">
    <div class="col">
      <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
        <h1 class="display-4">{{'event.details.usersAndAccessDatas' | translate}}</h1>
        <p class="lead">{{'event.details.usersAndAccessDatasDesc' | translate}}</p>
        <hr class="my-4">
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div class="card" *ngIf="event.deviceUsers.length > 0">
        <div class="card-header" style="position: relative;">
          {{'event.details.deviceUsers' | translate}}
          <div class="scroll-info">
            &rArr;&nbsp;&nbsp;Scroll
          </div>
        </div>
        <div class="card-body" style="width: 100%; overflow-y: scroll;">
          <div class="table-respnsive" style="width:750px;">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th style="width:150px;">{{'event.details.rule' | translate}}</th>
                  <th style="width:300px;">{{'event.details.username' | translate}}</th>
                  <th style="width:200px;">{{'event.details.accessCode' | translate}}</th>
                  <th style="width:100px;">{{'event.details.lvl1Code' | translate}}</th>
                  <!-- <th>{{'event.details.lvl2Code' | translate}}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let deviceUser of event.deviceUsers ">
                  <td>{{'event.details.' + deviceUser.deviceUserType.name | translate}}</td>
                  <!-- <td>{{deviceUser.username}}</td>  -->
                  <td>
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">{{deviceUser.name}}</span>
                      </div>
                      <input type="text" class="form-control" value="{{deviceUser.name}}"
                        placeholder="{{'event.details.changeYourDeviceUsername' | translate}}"
                        (keyup)="showHideconfirmToUpdateCheck($event, deviceUser, true)"
                        #deviceUserUsername />
                      <div class="input-group-append" *ngIf="deviceUser.confirmToUpdateCheck">
                        <button type="button" [ngClass]="deviceUser.name==='' ? 'btn btn-outline-secondary' : 'btn btn-outline-secondary red'"
                          (click)="updateDeviceUserUsername($event, deviceUserUsername, true, deviceUser.id)"><i
                            class="fa fa-save"></i></button>
                      </div>
                      <div class="input-group-append" *ngIf="!deviceUser.confirmToUpdateCheck">
                        <button type="button" [ngClass]="deviceUser.name==='' ? 'btn btn-outline-secondary' : 'btn btn-outline-secondary green'"
                          (click)="updateDeviceUserUsername($event, deviceUserUsername, true, deviceUser.id)"><i
                            class="fa fa-check"></i></button>
                      </div>
                    </div>
                  </td>
                  <td>{{deviceUser.pinCode}}</td>
                  <td>{{deviceUser.lvlOnePin}}</td>
                  <!-- <td>{{deviceUser.lvlTwoPin}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="error-message" *ngIf="showErrorNextStep">
      {{'event.details.errorDeviceUser' | translate}}
      </div>
      <div class="card" style="margin-top: 10px;" *ngIf='event.printers.length+selectablePrinterCount != 0'>
        <div class="card-header">
          {{'event.details.ownPrinterInformations' | translate}}
        </div>
        <div class="card-body">
          <div class="table-respnsive ">
            <table class="table table-sm ">
                <thead>
                    <tr>
                        <th>{{'event.details.printerName' | translate}}</th>
                        <th>{{'event.details.printerType' | translate}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let printer of event.printers " [className]="checkOwnPrinter(printer.id) ? 'own-printer' : 'rented-printer'">
                        <td>{{printer.name}}</td>
                        <td>{{printer.printerType.name}}</td>
                        <td class="text-right">
                            <button type="button" style="margin-right:-10px" class="btn btn-sm btn-primary" (click)="addRemovePrinterToCompanyClick(printer.id,'del')" *ngIf="checkOwnPrinter(printer.id)"><th>{{'event.details.printerRemoveBtn' | translate}}</th></button>
                            <span class="rented-label" *ngIf="!checkOwnPrinter(printer.id)">{{'event.details.rented' | translate}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card" *ngIf='selectablePrinterCount > 0'>
                <div class="card-body" style="padding-bottom: 0px;">
                    <div class="form-row align-items-center">
                        <div class="col">
                          <select class="form-control" [(ngModel)]="selectedPrinter">
                            <option *ngFor="let option of printerListOptions" [ngValue]="option.value">{{option.label}}</option>
                          </select>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-sm btn-default"
                            (click)="addRemovePrinterToCompanyClick(0,'add')"
                            ><th>{{'event.details.printerAssignBtn' | translate}}</th></button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-3" *ngIf="event.routers.length > 0">
        <div class="card-header">
          {{'event.details.routerInformations' | translate}}
        </div>
        <div class="card-body ">
          <div class="table-respnsive ">
            <table class="table table-sm ">
              <thead>
                <tr>
                  <th>{{'event.details.routerName' | translate}}</th>
                  <!-- <th>{{'event.details.routerStatus' | translate}}</th>
                            <th>{{'event.details.routerType' | translate}}</th> -->
                  <th>{{'event.details.channel' | translate}}</th>
                  <th>{{'event.details.routerMasterType' | translate}}</th>
                  <th>{{'event.details.networkName' | translate}}</th>
                  <th>{{'event.details.networkPassword' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let router of routers">
                  <td>{{router.name}}</td>
                  <!-- <td>{{router.status}}</td>
                            <td>{{router.routerType.name}}</td> -->
                  <td>{{router.channel}}</td>
                  <td>{{(router.master === 0 ? 'event.details.mainRouter' : 'event.details.slaveRouter') | translate}}
                  </td>
                  <td>{{router.networkName}}</td>
                  <td>{{router.networkPassword}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
