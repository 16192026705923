<div class="row">
    <div class="col">
        <div class="form-group">
            <label class="control-label col-form-label-sm">{{'offers.request.country' | translate}}
                *</label>
            <ng-select class="custom" [items]="countryOptions" [(ngModel)]="choosenCountry"
                (change)="onSelectChange('country')">
            </ng-select>
        </div>
    </div>
    <div class="col">
        <div class="form-group">
            <label class="control-label col-form-label-sm">{{'offers.request.region' | translate}}
                *</label>
            <ng-select class="custom" [items]="regionOptions" [(ngModel)]="choosenRegion" [disabled]="!choosenCountry"
                (change)="onSelectChange('region')">
            </ng-select>
        </div>
    </div>
    <div class="col">
        <div class="form-group">
            <label class="control-label col-form-label-sm">{{'offers.request.province' | translate}}
                *</label>
            <ng-select class="custom" [items]="provinceOptions" [(ngModel)]="choosenProvince"
                [disabled]="!choosenRegion" (change)="onSelectChange('province')">
            </ng-select>
        </div>
    </div>
    <div class="col">
        <div class="form-group">
            <label class="control-label col-form-label-sm">{{'offers.request.village' | translate}}
                *</label>
            <ng-select class="custom" [items]="villageOptions" [(ngModel)]="choosenVillage"
                [disabled]="!choosenProvince" (change)="onSelectChange('village')">
            </ng-select>
        </div>
    </div>
</div>