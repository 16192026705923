import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LOCAL_STORAGE } from '../../core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountService } from '../shared/account.service';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { Client } from 'src/app/shared/models/Client';
import { Company } from 'src/app/shared/models/Company';
import { Country } from 'src/app/shared/models/Country';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { Village } from 'src/app/shared/models/Village';
import { Language } from '../../core/language';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  public languages = Language.getAvailableLanguages();
  public currentLanguage: any;
  public clientForm: FormGroup;
  public companyForm: FormGroup;
  public clientErrorArray = new Array();
  public companyErrorArray = new Array();
  public countryOptions: Option[] = [];
  public regionOptions: Option[] = [];
  public provinceOptions: Option[] = [];
  public villageOptions: Option[] = [];
  public languageOptions: Option[] = [
    { value: 'de', label: 'register.german' },
    { value: 'it', label: 'register.italian' }
  ];
  public choosenVillage: any;
  public companyVillage: Village;
  public choosenProvince: number;
  public choosenRegion: number;
  public newPassword: string;
  public newPasswordRepeat: string;
  public newPasswordErrorMsg = '';
  public newPasswordRepeatErrorMsg = '';
  public titleOptions: Option[] = [
    { value: 'Herr', label: 'register.herr' },
    { value: 'Frau', label: 'register.frau' }
  ];
  public missingVillageErrorMsg = '';
  public villageObjects: Village[] = [];

  public locationGroupClass = "col";

  private _defaultCountry = Country.fromDto({ id: 1, name: 'Italy', vat: '0.22' });
  private _clientId: number;
  private _newClient: Client = new Client();
  private _newCompany: Company;

  constructor(private _toastr: ToastrService, private _formBuilder: FormBuilder, public jwtHelper: JwtHelperService,
    private _accountService: AccountService, private _translate: TranslateService) {
    this.currentLanguage = this._translate.currentLang;
    this.clientForm = this._formBuilder.group({
      title: ['Herr', <any>Validators.required],
      firstName: ['', [<any>Validators.required, <any>Validators.minLength(2)]],
      lastName: ['', [<any>Validators.required, <any>Validators.minLength(2)]],
      firstEmail: [{ value: '', disable: true }],
      secondEmail: ['', <any>Validators.email],
      firstPhone: ['', <any>Validators.required],
      secondPhone: ['', <any>Validators.minLength(5)],
      language: ['de', [<any>Validators.required]],
      newsletter: [false]
    });

    this.companyForm = this._formBuilder.group({
      name: ['', [<any>Validators.required, <any>Validators.minLength(2)]],
      firstEmail: ['', [<any>Validators.required, <any>Validators.email]],
      secondEmail: ['', <any>Validators.email],
      firstPhone: ['', <any>Validators.required],
      secondPhone: [''],
      fiscalCode: [''],
      vat: [''],
      pecEmail: ['', <any>Validators.email],
      isdNumber: [''],
      address: ['', <any>Validators.required],
      postalCode: ['', <any>Validators.required],
      city: [''],
      village: [''],
    });
  }

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this.jwtHelper.decodeToken(accessToken).data;
    this._clientId = tokenData.id;
    this._accountService.getAllVillages().subscribe(result => {
      this.villageObjects = result;
    });
    this._accountService.getSpecificClientById(this._clientId).subscribe(result => {
      console.log(result);
      this.clientForm.patchValue(result);
      if (result.company != null) {
        this.companyForm.patchValue(result.company);
        this._newCompany = result.company;
        if (this._newCompany.village) {
          this.companyVillage = this._newCompany.village;
          this.choosenVillage = this._newCompany.village;
        }
      }
      this._newClient = result;
    }, error => {
      console.log(error);
    });
  }

  getLocationName(locationName: string, language: string) {
    if (language === 'de') {
      return locationName.split('/').pop();
    } else {
      return locationName.split('/').slice(0, -1).join('/');
    }
  }

  onSubmitClientForm() {
    if (this.clientForm.valid) {
      this._newClient = Client.fromDto(this.clientForm.value, this._newClient);
      if (this.clientForm.value.newPassword !== undefined || this.clientForm.value.newPassword !== '') {
        this._newClient.password = this.clientForm.value.newPassword;
      }
      this._accountService.updateClient(this._newClient).subscribe(result => {
        this._toastr.success(this._translate.instant('account.clientUpdateSuccess'));
      }, error => {
        console.log(error);
      });
    }
    this.clientErrorArray = ValidationUtils.getFormValidationErrors(this.clientForm, 'account');
  }

  onSubmitCompanyForm() {
    this.missingVillageErrorMsg = '';
    if (this.companyForm.valid) {
      if (!this.choosenVillage) {
        this.locationGroupClass = "col border-red";
        this.missingVillageErrorMsg = this._translate.instant('account.errorMsg.missingVillage');
        return;
      }
      this.missingVillageErrorMsg = null;
      this.locationGroupClass = "col";
      if (this._newCompany === undefined) {
        this._newCompany = new Company();
        this._newCompany.status = 1;
        this._newCompany.country = this._defaultCountry;
      }
      this._newCompany = Company.fromDto(this.companyForm.value, this._newCompany);
      this._newCompany.village = this.villageObjects.filter(village => village.id === this.choosenVillage.value)[0];
      this._newClient.company = this._newCompany;
      this._accountService.updateCompany(this._newClient.id, this._newClient.company).subscribe(result => {
        this._toastr.success(this._translate.instant('account.companyAdded'));
      }, error => {
        console.log(error);
      });
    }
    this.companyErrorArray = ValidationUtils.getFormValidationErrors(this.companyForm, 'account');
    console.log(this.companyErrorArray);
  }

  // checkFiscalCodeAndVat(fiscalCodeKey: string, vatKey: string): any {
  //   return (group: FormGroup) => {
  //     const fiscalCodeInput = group.controls[fiscalCodeKey],
  //       vatInput = group.controls[vatKey];
  //     if ((!fiscalCodeInput.value) && (!vatInput.value)) {
  //       fiscalCodeInput.setErrors({ fiscalCodeVatRequired: true });
  //       vatInput.setErrors({ fiscalCodeVatRequired: true });
  //     } else {
  //       fiscalCodeInput.setErrors(null);
  //       vatInput.setErrors(null);
  //     }
  //   };
  // }

  updateClientPassword() {
    this.newPasswordErrorMsg = '';
    this.newPasswordRepeatErrorMsg = '';
    if (!this.newPassword) {
      this.newPasswordErrorMsg = this._translate.instant('account.errorMsg.required');
      return;
    }
    if (!this.newPasswordRepeat) {
      this.newPasswordRepeatErrorMsg = this._translate.instant('account.errorMsg.required');
      return;
    }
    if (this.newPassword !== this.newPasswordRepeat) {
      this.newPasswordRepeatErrorMsg = this._translate.instant('account.errorMsg.passwordNotEqual');
      return;
    }
    this._accountService.changeClientPassword(this._clientId, this.newPassword).subscribe(result => {
      this.newPassword = '';
      this.newPasswordRepeat = '';
      this._toastr.success(this._translate.instant('account.passwordSuccessUpdate'));
    });
  }

}
