<div class="container">
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-6 mx-auto">
            <div class="text-center mt-5 mb-3">
                <img class="logo" src="/assets/famulus_logo.png" alt="Famulus" height="100">
            </div>
            <div class="card card-signin">
                <div class="card-body">
                    <div class="card-title text-center">
                        <h5>{{'reset.insertPassword' | translate}}</h5>
                    </div>
                    <form [formGroup]="resetForm" (ngSubmit)="onReset()" class="needs-validation">
                        <app-form-group-input inputType="password" label="{{'reset.password' | translate}}"
                            name="password" id="password" placeHolder="{{'reset.password' | translate}}"
                            [required]="true" formControlNameValue="password" [error]="errorArray['password']"
                            [formGroup]="resetForm" ngDefaultControl>
                        </app-form-group-input>
                        <app-form-group-input inputType="password" label="{{'reset.retypePassword' | translate}}"
                            name="retypePassword" id="retypePassword"
                            placeHolder="{{'reset.retypePassword' | translate}}" [required]="true"
                            formControlNameValue="retypePassword" [error]="errorArray['retypePassword']"
                            [formGroup]="resetForm" ngDefaultControl>
                        </app-form-group-input>
                        <div class="text-center mt-4">
                            <button class="btn btn-primary btn-signin btn-block btn-sm"
                                type="submit">{{'reset.resetPassword' | translate}}</button>
                        </div>
                    </form>
                    <p class="text-muted text-center mt-5" style="margin-bottom: 0px;"><small>Copyright
                            RR-Solutions</small>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>