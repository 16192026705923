export const URL_BASE = 'https://backend.famulus.it/api/v1/client';
// export const URL_BASE = 'https://famulusbackend.rrsolutions.ro/api/v1/client';
export const URL_IMAGES = 'https://famulusimages.rrsolutions.ro/';
// export const URL_BASE = 'https://backend.famulus.it/api/v1/client';
// export const URL_BASE = 'http://localhost:3001/api/v1/client';

// export const COOKIE_DOMAIN = '.rrsolutions.ro'; // share cookies also with client
export const COOKIE_DOMAIN = '.famulus.it'; // share cookies also with client
// export const COOKIE_DOMAIN = 'localhost'; // share cookies also with client

// const { version: appVersion } = require('../../../package.json');
export const APP_VERSION = '0.0.1';

export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'access_token',
  FIRST_LOGIN: 'first_login'
};
