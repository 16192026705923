import { Component, OnInit } from '@angular/core';
import { EventService } from '../../shared/event.service';
import { Chart } from 'angular-highcharts';
import { Point } from 'highcharts';
import { TurnOverProduct } from 'src/app/shared/models/TurnOverProduct';
import { Option } from 'src/app/shared/simple-form-group-select/simple-form-group-select.component';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  public totalEventAmount = 0;
  public totalEventOrders = 0;
  public productArray: TurnOverProduct[] = [];
  public productTableArray: TurnOverProduct[] = [];
  public choosenProductCategory = '';
  public productCategoryOptions: Option[] = [];
  public categoryPieChart: Chart;
  point = new Point();
  myArray = [];

  constructor(private _eventService: EventService, private _translate: TranslateService) { }

  ngOnInit() {
    this._eventService.updateCurrentActiveSubPage('statistics');
      this._eventService.currentEventObject.subscribe(eventResult => {
        if (eventResult) {
          forkJoin([
            this._eventService.getEventTurnOver(eventResult.id),
            this._eventService.getCategoriesTurnOver(eventResult.id),
            this._eventService.getDevicesTurnOver(eventResult.id)
          ]).subscribe(result => {
            this.productArray = result[0].sort((a, b) => b.amount - a.amount);
            this.productTableArray = this.productArray;
            this.productArray.map(product => {
              if (!this.productCategoryOptions.find(function (category) { return category.value === product.categoryId.toString(); })) {
                this.productCategoryOptions.push({ value: product.categoryId.toString(), label: product.categoryName });
              }
            });
            result[1].forEach(category => {
              this.totalEventAmount += category.amount;
              this.totalEventOrders += Number(category.quantity);
              this.point.name = category.categoryName;
              this.point.y = Number(category.quantity);
              this.myArray.push(
                {name: this.point.name, y: this.point.y},
              );
            });
            this.categoryPieChart = new Chart ({
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
              },
              title: {
                text: this._translate.instant('event.statistic.graphLabel')
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>'
              },
              plotOptions: {
                pie: {
                  allowPointSelect: false,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                  }
                }
              },
              series: [{
                name: this._translate.instant('event.statistic.quantity'),
                colorByPoint: true,
                type: undefined,
                data: this.myArray
              }]
            });
          });
        }
      });
  }

  onProductCategoryChange() {
    console.log(this.choosenProductCategory);
    if (this.choosenProductCategory !== '') {
      this.productTableArray = this.productArray.filter(product => product.categoryId.toString() === this.choosenProductCategory);
    } else {
      this.productTableArray = this.productArray;
    }
  }

}
