import { Component, OnInit } from '@angular/core';
import { EventService } from '../../shared/event.service';
import { OnlineEvent } from 'src/app/shared/models/OnlineEvent';
import { Event } from 'src/app/shared/models/Event';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE } from 'src/app/core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-advertising-event',
  templateUrl: './advertising-event.component.html',
  styleUrls: ['./advertising-event.component.css']
})
export class AdvertisingEventComponent implements OnInit {

  public onlineEvent = new OnlineEvent();
  public imageUrl: any;
  public showSuccessMsg = false;
  public startTimeArray = new Array();
  public eventEndDate = '';
  public eventNotFound = false;
  private _imageToUpload: File = null;
  private _event: Event = new Event();
  public startDateToShow = '';
  public endDateToShow = '';
  private _imageRemoved: boolean = false;
  public eventOfferId: string;
  constructor(
    private _eventService: EventService,
    private _toastr: ToastrService,
    private _translate: TranslateService,
    private _route: ActivatedRoute,
    private _jwtHelper: JwtHelperService,
    private _router: Router
  ) {}

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this._jwtHelper.decodeToken(accessToken).data;
    this._route.parent.paramMap.subscribe(params => {
      console.log(params.get('eventId'));
      this._eventService.getEventForClient(parseInt(params.get('eventId')), tokenData.id).subscribe(result => {
        if (result) {
          console.log('mioEvento:', result);
          this._eventService.updateCurrentActiveSubPage('advertising');
          this.eventNotFound = false;
          this._event = result;
          this._eventService.getOnlineEventByEventId(this._event.id.toString()).subscribe(resultOnlineEvent => {
            this.eventOfferId = this._event.offerId;
            this.onlineEvent = (resultOnlineEvent ? resultOnlineEvent : new OnlineEvent());
            if (!this.onlineEvent.startDate) {
              this.onlineEvent.startDate = this._event.startDate;
            }
            if (!this.onlineEvent.endDate) {
              this.onlineEvent.endDate = moment(this._event.endDate).subtract(1, "day").format("YYYY-MM-DD");
            }
            this.startDateToShow = this.onlineEvent.startDate;
            this.endDateToShow = this.onlineEvent.endDate;
            if (!this.onlineEvent.eventNameDe) {
              this.onlineEvent.eventNameDe = this._event.eventName;
            }
            if (this.onlineEvent.pictureName) {
              // this.imageUrl = 'http://localhost:3001/onlineEventImages/' + this._event.id + '/' + this.onlineEvent.pictureName;
              this.imageUrl = this.onlineEvent.pictureName;
            }
            this.eventEndDate = this._event.endDate;
            this.onlineEvent.eventDescriptionDe = (this.onlineEvent.eventDescriptionDe ? this.onlineEvent.eventDescriptionDe.replace(/<br\s*[\/]?>/gi, '\n') : '');
            this.onlineEvent.eventDescriptionIt = (this.onlineEvent.eventDescriptionIt ? this.onlineEvent.eventDescriptionIt.replace(/<br\s*[\/]?>/gi, '\n') : '');
            const existingHoursArray = (this.onlineEvent.startHour ? JSON.parse(this.onlineEvent.startHour) : undefined);
            if (!existingHoursArray) {
              for (const m = moment(this.onlineEvent.startDate); m.diff(this.onlineEvent.endDate, 'days') <= 0; m.add(1, 'days')) {
                this.startTimeArray.push({
                  date: m.format('YYYY-MM-DD'),
                  time: { hour: 0, minute: 0, second: 0 }
                });
              }
            } else {
              for (const element of existingHoursArray) {
                const timeExplode = element.time.split(':');
                this.startTimeArray.push({
                  date: element.date,
                  time: { hour: parseInt(timeExplode[0], 10), minute: parseInt(timeExplode[1], 10), second: 0 }
                });
              }
            }
            console.log(this.onlineEvent);
          });
          console.log(result);
        }
      }, error => {
        this._eventService.updateCurrentActiveSubPage('advertising');
          this._route.parent.paramMap.subscribe(params => {
            this.eventOfferId = params.get('eventId');
            this.eventNotFound = true;
            this._eventService.getOnlineEventByEventOfferId(this.eventOfferId.toString()).subscribe(resultOnlineEvent => {
              if ( resultOnlineEvent) {
                this.onlineEvent = (resultOnlineEvent ? resultOnlineEvent : new OnlineEvent());
                console.log('quiOnlineEvent',this.onlineEvent);
                this.startDateToShow = this.onlineEvent.startDate;
                this.endDateToShow = this.onlineEvent.endDate;
                if (!this.onlineEvent.eventNameDe) {
                  this.onlineEvent.eventNameDe = '';
                }
                if (this.onlineEvent.pictureName) {
                  // this.imageUrl = 'http://localhost:3001/onlineEventImages/' + this._event.id + '/' + this.onlineEvent.pictureName;
                  this.imageUrl = this.onlineEvent.pictureName;
                }
                this.onlineEvent.eventDescriptionDe = (this.onlineEvent.eventDescriptionDe ? this.onlineEvent.eventDescriptionDe.replace(/<br\s*[\/]?>/gi, '\n') : '');
                this.onlineEvent.eventDescriptionIt = (this.onlineEvent.eventDescriptionIt ? this.onlineEvent.eventDescriptionIt.replace(/<br\s*[\/]?>/gi, '\n') : '');
                const existingHoursArray = (this.onlineEvent.startHour ? JSON.parse(this.onlineEvent.startHour) : undefined);
                if (!existingHoursArray) {
                  for (const m = moment(this.onlineEvent.startDate); m.diff(this.onlineEvent.endDate, 'days') <= 0; m.add(1, 'days')) {
                    this.startTimeArray.push({
                      date: m.format('YYYY-MM-DD'),
                      time: { hour: 0, minute: 0, second: 0 }
                    });
                  }
                } else {
                  for (const element of existingHoursArray) {
                    const timeExplode = element.time.split(':');
                    this.startTimeArray.push({
                      date: element.date,
                      time: { hour: parseInt(timeExplode[0], 10), minute: parseInt(timeExplode[1], 10), second: 0 }
                    });
                  }
                }
                console.log('myOnlineEvent:', this.onlineEvent);
              }
              else {
                this._router.navigate(['event/my-events']);
              }
            });
          });
        }
      );
    });


  }

  handleImageFileInput(files: FileList) {
    if (files.length === 0) { return; }
    if (files.length > 1) { return; }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return this._toastr.warning(this._translate.instant('event.advertiseEvent.imageError'));
    }
    const reader = new FileReader();
    this._imageToUpload = files.item(0);
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imageUrl = reader.result;
    };
    this._imageRemoved = false;
  }

  removeImagePreview() {
    this._imageToUpload = null;
    this.imageUrl = null;
    this.onlineEvent.pictureName = undefined;
    this._imageRemoved = true;
  }

  submitOnlineEvent() {
    this.onlineEvent.startHour = JSON.stringify(this.startTimeArray);
    this.showSuccessMsg = false;
    let imageName: string;
    if (this._imageToUpload) {
      const imageFileNameArray = this._imageToUpload.name.split('.');
      const imageNameFinale = imageFileNameArray[imageFileNameArray.length - 1].toLocaleLowerCase();
      imageName = Math.random().toString(36).slice(-8) + '.' + imageNameFinale;
    }
    if (!this.onlineEvent.eventNameDe && !this.onlineEvent.eventNameIt) {
      return this._toastr.warning(this._translate.instant('event.advertiseEvent.missingTitleError'));
    }
    if (this.onlineEvent.eventNameDe) {
      if (this.onlineEvent.eventNameDe.length > 240) {
        return this._toastr.warning(this._translate.instant('event.advertiseEvent.titleDeTooLong'));
      }
    }
    if (this.onlineEvent.eventNameIt) {
      if (this.onlineEvent.eventNameIt.length > 240) {
        return this._toastr.warning(this._translate.instant('event.advertiseEvent.titleItTooLong'));
      }
    }
    this.onlineEvent.eventDescriptionDe = this.onlineEvent.eventDescriptionDe + ' ';
    this.onlineEvent.eventDescriptionIt = this.onlineEvent.eventDescriptionIt + ' ';

    const type = (this.eventNotFound) ? 'eventOffer' : 'event';

    this._eventService.insertOnlineEvent(type, this._imageToUpload, imageName, this.eventOfferId ? this.eventOfferId.toString() : this._event.id.toString(), this.onlineEvent, this._imageRemoved).subscribe(result => {
      this.onlineEvent = result;
      this.onlineEvent.eventDescriptionDe = (this.onlineEvent.eventDescriptionDe ? this.onlineEvent.eventDescriptionDe.replace(/<br\s*[\/]?>/gi, '\n') : '');
      this.onlineEvent.eventDescriptionIt = (this.onlineEvent.eventDescriptionIt ? this.onlineEvent.eventDescriptionIt.replace(/<br\s*[\/]?>/gi, '\n') : '');
      this.showSuccessMsg = true;
      this._imageRemoved = false;
    }, error => {
      console.error(error);
    });
  }

  onLocationChange(coordinates) {
    this.onlineEvent.latitude = coordinates.lat;
    this.onlineEvent.longitude = coordinates.long;
  }

  changeOnlineEventPublishValue() {
    if (this.onlineEvent.id) {
      this._eventService.changePublishValue(this.onlineEvent.id.toString(),
        this.onlineEvent.onlinePublish.toString()).subscribe(result => { });
    }
  }



}
