import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { tileLayer, latLng, marker } from 'leaflet';

// just an interface for type safety.
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

@Component({
  selector: 'app-event-location-card',
  templateUrl: './event-location-card.component.html',
  styleUrls: ['./event-location-card.component.css']
})
export class EventLocationCardComponent implements OnInit, OnChanges {
  public zoom = 10;
  public eventDefaultLatitude = '46.6553557';
  public eventDefaultLongitude = '10.8692822';
  public originalLatitude: string;
  public originalLongitude: string;
  public googleMapsUrl: string;

  public options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: this.zoom,
    center: latLng(parseFloat(this.eventDefaultLatitude), parseFloat(this.eventDefaultLongitude))
  };
  public layers = [];

  // @Input()
  // event: Event;

  @Input()
  latitude: string;

  @Input()
  longitude: string;

  @Input()
  disableEdit = false;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onLocationChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.latitude && this.longitude) {
      if (changes.latitude.firstChange && changes.longitude.firstChange) {
        this.originalLatitude = this.latitude;
        this.originalLongitude = this.longitude;
      }
      this._addMarker(this.latitude, this.longitude);
    }
  }

  resetDefaultLocation() {
    this.latitude = this.originalLatitude;
    this.longitude = this.originalLongitude;
    this._addMarker(this.latitude, this.longitude);
    this.zoom = 7;
  }

  _addMarker(lat: string, lng: string) {
    this.layers = [];
    this.layers.push(marker([parseFloat(lat), parseFloat(lng)]));
    this.options.center = latLng(parseFloat(lat), parseFloat(lng));
    //this.options.center = latLng(parseFloat(this.eventDefaultLatitude), parseFloat(this.eventDefaultLongitude));
  }

  onMapClick(event: any) {
    if (!this.disableEdit) {
      this.latitude = event.latlng.lat.toString();
      this.longitude = event.latlng.lng.toString();
      this._addMarker(this.latitude, this.longitude);
      this.onLocationChange.emit({ lat: this.latitude, long: this.longitude });
    }
  }

  onInputLatLngChange(event: any) {
    if (!this.disableEdit) {
      this._addMarker(this.latitude, this.longitude);
      this.onLocationChange.emit({ lat: this.latitude, long: this.longitude });
    }
  }

}
