import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'dashboard.pageTitle', icon: 'pe-7s-study', class: '' },
  { path: '/account', title: 'topNavbar.account', icon: 'pe-7s-user', class: '' },
  { path: '/request/event', title: 'sidebar.eventRequest', icon: 'pe-7s-note2', class: '' },
  { path: '/event/my-events', title: 'sidebar.myEvents', icon: 'pe-7s-news-paper', class: '' },
  { path: '/price-list/my-pricelists', title: 'sidebar.myPricelists', icon: 'pe-7s-bookmarks', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
  menuItems: any[];
  public date = new Date();

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

}
