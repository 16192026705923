<div [formGroup]="formGroup" class="form-group">
    <label class="control-label col-form-label-sm" *ngIf="label !== ''">{{label}}</label>
    <input type="{{inputType}}"
           class="form-control form-control-sm {{additionalClass}} {{error ? 'is-invalid' : ''}}"
           [formControlName]="formControlNameValue"
           name="{{name}}" id="{{id}}"
           (ngModelChange)="change()" 
           placeholder="{{placeHolder}}"
           [required]="required"/>
    <div class="invalid-feedback" [hidden]="!error">
        {{error | translate}}
    </div>
  </div>
