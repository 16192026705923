import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, DoCheck, IterableDiffers } from '@angular/core';
import { PriceList } from 'src/app/shared/models/PriceList';
import { PriceListService } from 'src/app/price-list/shared/price-list.service';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';

@Component({
  selector: 'app-products-bulk-actions',
  templateUrl: './products-bulk-actions.component.html',
  styleUrls: ['./products-bulk-actions.component.css']
})
export class ProductsBulkActionsComponent implements OnInit, OnChanges, DoCheck {

  public selectedCategory: number;
  public hideChangeCategory = true;
  private _differ: any;

  @Input()
  priceList: PriceList;

  @Input()
  productIdsArray = new Array<string>();

  @Input()
  productCategoryOptions: Option[] = [];

  @Output()
  unselectAllEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  selectAllEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  getPriceListProductCategories: EventEmitter<ProductCategory[]> = new EventEmitter<ProductCategory[]>();

  constructor(differs: IterableDiffers, private _priceListService: PriceListService, private _toastr: ToastrService,
    private _translate: TranslateService) {
    this._differ = differs.find([]).create(null);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any): void {
  }

  ngDoCheck() {
    const change = this._differ.diff(this.productIdsArray);
    if (change) {
      // console.log(change);
    }
  }

  changeProductsCategory() {
    if (this.selectedCategory === undefined) {
      return;
    }
    console.log(this.selectedCategory);
    console.log(this.priceList.productCategories);
    const productCategory = this.priceList.productCategories.filter(category =>
      category.id.toString() === this.selectedCategory.toString())[0];
    console.log(productCategory);
    this._priceListService.changeMultipleProductsCategory(this.productIdsArray, productCategory).subscribe(result => {
      this.getPriceListProductCategories.emit(result);
      this._toastr.success(this._translate.instant('price-list.manage.productsCategoryChangeSuccessMsg'));
      this.selectedCategory = undefined;
      this.hideChangeCategory = true;
    }, error => {
      console.log(error);
    });
  }

  duplicateSelection() {
    if (this.productIdsArray.length > 0) {
      this._priceListService.duplicateMultipleProducts(this.productIdsArray).subscribe(result => {
        this.getPriceListProductCategories.emit(result);
        this._toastr.success(this._translate.instant('price-list.manage.productsDuplicateSuccessMsg'));
      }, error => {
        console.log(error);
      });
    }
  }

  deleteSelection() {
    if (this.productIdsArray.length > 0) {
      this._priceListService.deleteMultipleProducts(this.productIdsArray).subscribe(result => {
        this.getPriceListProductCategories.emit(result);
        this._toastr.success(this._translate.instant('price-list.manage.productsDeleteSuccessMsg'));
      }, error => {
        console.log(error);
      });
    }
  }

}
