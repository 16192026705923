import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/shared/models/Product';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PriceListService } from 'src/app/price-list/shared/price-list.service';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { PriceList } from 'src/app/shared/models/PriceList';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-product-form',
  templateUrl: './edit-product-form.component.html',
  styleUrls: ['./edit-product-form.component.css']
})
export class EditProductFormComponent implements OnInit, OnChanges {

  public editProductForm: FormGroup;
  public editErrorArray = new Array();

  @Input()
  product: Product;

  @Input()
  productCategories: ProductCategory[];

  @Input()
  productCategoryOptions: Option[];

  @Input()
  priceList: PriceList;

  @Output()
  getCancelEditModeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _formBuilder: FormBuilder, private _priceListService: PriceListService, private _toastr: ToastrService,
    private _translate: TranslateService) {
    this.editProductForm = this._formBuilder.group({
      categoryId: ['', <any>Validators.required],
      name: ['', [<any>Validators.required, <any>Validators.maxLength(32)]],
      extraInfo: ['', <any>Validators.maxLength(32)],
      price: ['0.00', <any>Validators.pattern(/^\d{0,8}(\.\d{1,4})?$/)],
      stockPerDay: ['0', <any>Validators.pattern(/^[1-9][0-9]*$/)]
    },
      {
        validator: ValidationUtils.checkStringLength('name', 'extraInfo')
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.editProductForm.patchValue({
      categoryId: this.product.productCategory.id,
      name: this.product.name,
      extraInfo: (this.product.extraInfo != null ? this.product.extraInfo : ''),
      price: this.product.price,
      stockPerDay: this.product.stockPerDay
    });
  }

  ngOnInit() {
  }

  onProductSubmit() {
    if (this.editProductForm.valid) {
      const product = Product.fromDto(this.editProductForm.value);
      product.status = this.product.status;
      product.id = this.product.id;
      product.itemOrder = this.product.itemOrder;
      product.productCategory = this.productCategories.filter(category =>
        category.id.toString() === this.editProductForm.value.categoryId.toString())[0];

      console.log('productToEdit:',product);
      this._priceListService.updateProduct(product).subscribe(result => {
        console.log('result:',result);
        this.priceList.productCategories = result;
        this.getCancelEditModeEvent.emit();
        this._toastr.success(this._translate.instant('price-list.manage.productSuccessUpdateMsg'));
      }, error => {
        console.log(error);
      });
    }

    this.editErrorArray = ValidationUtils.getFormValidationErrors(this.editProductForm, 'price-list.manage');
  }

  cancelEditButtonClick() {
    this.getCancelEditModeEvent.emit();
  }

}
