export class Language {

  // static EN = new Language('en', true, 'uk');
  static DE = new Language('de', true, 'de');
  static IT = new Language('it', true, 'it');

  static values(): Language[] {
    return Object.getOwnPropertyNames(this)
      .map(p => Object.getOwnPropertyDescriptor(this, p))
      .filter(p => p.enumerable)
      .filter(p => p.value instanceof Language)
      .map(p => p.value);
  }

  static getAvailableLanguages(): Language[] {
    return this.values().filter(l => l.available);
  }

  static fromLocale(locale: string): Language {
    return this.values().find(l => l.locale === locale);
  }

  private constructor(public locale: string, public available: boolean, public flag: string) {
  }
}
