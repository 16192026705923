<div class="row">
  <div class="col">
    <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
      <h1 class="display-4">{{'event.statistic.pageTitle' | translate}}</h1>
      <p class="lead">{{'event.statistic.pageSubTitle' | translate}}</p>
      <hr class="my-4">
    </div>
  </div>
</div>
<div class="row">
  <div class="col-5">
    <div [chart]="categoryPieChart"></div>
  </div>
  <div class="col">
    <div class="card-group border border-primary">
      <div class="card border">
        <div class="card-body text-center">
          <h5 class="card-title"><strong>{{'event.statistic.salesLabel' | translate}}</strong></h5>
          <p class="card-text form-text text-muted">{{totalEventAmount | currency:'€ '}}</p>
        </div>
      </div>
      <div class="card border">
        <div class="card-body text-center">
          <h5 class="card-title"><strong>{{'event.statistic.ordersLabel' | translate}}</strong></h5>
          <p class="card-text form-text text-muted">{{totalEventOrders}}</p>
        </div>
      </div>
    </div>
    <hr />
  </div>
</div>
<hr />
<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>{{'event.statistic.productName' | translate}}</th>
              <th width="20%">
                {{'event.statistic.category' | translate}}
                <select class="form-control form-control-sm" [(ngModel)]="choosenProductCategory"
                  (change)="onProductCategoryChange()">
                  <option value=""></option>
                  <option *ngFor="let category of productCategoryOptions" [value]="category.value">{{category.label}}
                  </option>
                </select>
              </th>
              <th class="text-center">{{'event.statistic.quantity' | translate}}</th>
              <th class="text-center">{{'event.statistic.unitPrice' | translate}}</th>
              <th class="text-center">{{'event.statistic.totalAmount' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of productTableArray">
              <td>{{product.productName}}</td>
              <td>{{product.categoryName}}</td>
              <td class="text-center">{{product.quantity}}</td>
              <td class="text-center">{{product.price | currency:'€ '}}</td>
              <td class="text-center"><span class="badge badge-info"
                  style="font-size: 100% !important;">{{product.amount | currency:'€ '}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
