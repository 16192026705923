import { Component, OnInit } from '@angular/core';
import { EventService } from '../../shared/event.service';
import { Event } from 'src/app/shared/models/Event';
import { Router } from 'src/app/shared/models/Router';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { Printer } from 'src/app/shared/models/Printer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-users',
  templateUrl: './device-users.component.html',
  styleUrls: ['./device-users.component.css']
})
export class DeviceUsersComponent implements OnInit {

  public event: Event = new Event();
  public routers: Router[];
  public disableDeviceMenuItem = false;
  public printerListOptions: Option[];
  public ownPrinters: Printer[] = [];
  public requestedPrinter: Printer;
  public selectedPrinter: Printer;
  public assignedPrinters: Printer [] = [];
  public selectablePrinterCount = 0;
  public showErrorNextStep = false;

  constructor(private _eventService: EventService, private _translateService: TranslateService) { }

  ngOnInit() {
    this._eventService.currentEventObject.subscribe(result => {
      if (result) {
        this.event = result;
        console.log('event:', this.event);
        this.routers = this.event.routers;
        console.log(this.event.deviceUsers);
        if (this.event.requestedPrinterTypes.length === 0 && this.event.requestedRouterTypes.length === 0) {
          if (this.event.deviceUsers.length === 0) {
            this.disableDeviceMenuItem = true;
          }
        } else {
          if (this.event.deviceUsers.length === 0 ||
            this.event.routers.length < this._getRequestedQuantity(this.event.requestedRouterTypes) ||
            this.event.printers.length < this._getRequestedQuantity(this.event.requestedPrinterTypes)) {
            this.disableDeviceMenuItem = true;
          }
        }
        // added by Ivan 13/10/2021
        this.disableDeviceMenuItem = false;
        //
        this.populatePrinterSelect();
        this.countSelectablePrinters();
      }
    });
    this._eventService.updateCurrentActiveSubPage('devices');
  }

  updateDeviceUserUsername(e, element: any, update: boolean, deviceUserId: number) {
    if (!update) {
      element.value = '';
      return;
    }
    const newUsername = element.value;
    if (newUsername === '') {
      console.error('Username can\'t be empty');
      return;
    }
    this._eventService.updateDeviceUserUsername(newUsername, deviceUserId).subscribe(result => {
      const deviceUser = this.event.deviceUsers.filter(devUser => devUser.id === deviceUserId)[0];
      deviceUser.name = newUsername;
      element.value = newUsername;

      if (deviceUser.name === newUsername) {
        const classList = e.target.classList;
        deviceUser.confirmToUpdateCheck = false;
      }

    });


    this._eventService.currentEventObject.subscribe(result => {
      if (result) {
        this.event = result;
        this.routers = this.event.routers;
        console.log(this.event.deviceUsers);
      }
    });

    if (update === true) {
      if (e.target.classList.contains('fa')) {
        const classList = e.target.parentElement.classList;
        classList.add('green');
      } else {
        const classList = e.target.classList;
        classList.add('green');
      }
    }

  }

  showHideconfirmToUpdateCheck(e, deviceUser, show) {
    if (e.srcElement.value !== deviceUser.name) {
      deviceUser.confirmToUpdateCheck = true;
      this.showErrorNextStep = true;
    }
    if (e.srcElement.value !== deviceUser.name) {
      deviceUser.confirmToUpdateCheck = true;
      this.showErrorNextStep = true;
    }
  }

  populatePrinterSelect() {
    this.printerListOptions = [{ value: undefined, label: this._translateService.instant('event.details.printerSelect')}];
    this._eventService.getPrintersByCompany().subscribe(printers => {
      this.ownPrinters = printers;
      printers.forEach(printer => {
        if (this.event.printers.length === 0) {
          this.printerListOptions.push({ value: printer, label: printer.name });
        } else {
          let found = false;
          this.event.printers.forEach(printerE => {
            if (printer.id === printerE.id) {
              found = true;
            }
          });
          if (!found) {
            this.printerListOptions.push({ value: printer, label: printer.name });
          }
        }
      });
    }, error => {
      console.log(error);
    });
  }

  addRemovePrinterToCompanyClick(printerId: number, action: string) {
      if (action === 'add') {
        if (this.selectedPrinter) {
          // call backend endpoint for assign printer to event
          this._eventService.printersAssignUnassignToEvent(this.event.id, this.selectedPrinter.id, 1 ).subscribe(result => {
            // delete from select box
            const index = this.printerListOptions.findIndex(printer => printer.value === this.selectedPrinter);
            this.printerListOptions.splice(index, 1);
            // add to event object
            this.event.printers.push(this.selectedPrinter);
            // add to list of assigned printers
            // set selected printer as undefined
            this.selectedPrinter = undefined;
            this.countSelectablePrinters();
          }
          , error => {
            console.log(error);
          });
        }
      }
      if (action === 'del') {

        // call backend endpoint for unassign printer to event
        this._eventService.printersAssignUnassignToEvent(this.event.id, printerId, 0).subscribe(result => {
          // get clicked printer object
          const index = this.event.printers.findIndex(printer => printer.id === printerId);
          const currentPrinterObj = this.event.printers[index];
          // remove event object
          this.event.printers.splice(index, 1);
          // empty and repopolate printer select
          this.printerListOptions = undefined;
          this.populatePrinterSelect();
          this.countSelectablePrinters();
        }
        , error => {
          console.log(error);
        });
      }
  }

  countSelectablePrinters() {
    this.selectablePrinterCount = 0;
    let requestedSoftwareTypes = 0;
    this.event.requestedSoftwareTypes.forEach(element => {
      if (element.softwareType.id === 4) /*printer*/ {
        requestedSoftwareTypes = element.quantity;
        this.selectablePrinterCount += element.quantity;
      }
    });
    console.log('this.selectablePrinterCount:', this.selectablePrinterCount);
    this.event.requestedPrinterTypes.forEach(element => {
      this.selectablePrinterCount -= element.quantity;
    });
    console.log('this.selectablePrinterCount:', this.selectablePrinterCount);
    console.log('ownPrinters:', this.ownPrinters);
    this.event.printers.forEach(element => {
      if (this.ownPrinters.findIndex(printer => printer.id === element.id) !== -1) {
        this.selectablePrinterCount -= 1;
      }
    });
    console.log('this.selectablePrinterCount:', this.selectablePrinterCount);
    if (this.event.printers.length === requestedSoftwareTypes) {
      this.selectablePrinterCount = 0;
    }
  }

  checkOwnPrinter(printerId: number): boolean {
    return this.ownPrinters.findIndex(printer => printer.id === printerId) !== -1;
  }

  private _getRequestedQuantity(objectArray: any) {
    let amount = 0;
    objectArray.map(function (object) {
      amount += object.quantity;
    });
    return amount;
  }

}
