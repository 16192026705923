import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { ActivatedRoute, Router as AgularRouter } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public resetForm: FormGroup;
  public errorArray = new Array();
  public notMatchPassword = false;
  private currentRecoveryCode;

  constructor(private _formBuilder: FormBuilder, private _route: ActivatedRoute, private _authService: AuthService,
    private _router: AgularRouter, private _translate: TranslateService, private _toast: ToastrService) {
    this.resetForm = this._formBuilder.group({
      password: ['', <any>Validators.required],
      retypePassword: ['', <any>Validators.required],
    }, {
      validator: [ValidationUtils.checkIfMatchingPasswords('password', 'retypePassword')],
    });
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.currentRecoveryCode = params['recoveryCode'];
      this._authService.getClientSpecificInformations(this.currentRecoveryCode).subscribe(result => {
      }, error => {
        console.log(error);
        this._router.navigate(['/login']);
      });
    });
  }

  onReset() {
    if (this.resetForm.valid) {
      this._authService.resetClientPassword(this.currentRecoveryCode, this.resetForm.get('retypePassword').value).subscribe(result => {
        this._router.navigate(['/login']);
        // TODO: show success message?
        this._toast.success(this._translate.instant('reset.passwordChangeSuccess'));
      });
    } else {
      this.errorArray['retypePassword'] = this._translate.instant('reset.passwordNotMatchError');
    }
  }
}
