<div class="wrapper">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-top-navbar title="{{'account.account' | translate}}"></app-top-navbar>
        <div class="main-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-xl-6">
                        <div class="card">
                            <div class="header">
                                <h4 class="title">{{'account.editAccount' | translate}}</h4>
                            </div>
                            <div class="content">
                                <form [formGroup]="clientForm" (ngSubmit)="onSubmitClientForm()"
                                    class="needs-validation">
                                    <div class="row">
                                        <div class="col-4">
                                            <app-form-group-select name="title" id="title"
                                                label="{{'register.title' | translate}} *" formControlNameValue="title"
                                                [formGroup]="clientForm" [error]="clientErrorArray['title']"
                                                required="true" ngDefaultControl [options]="titleOptions">
                                            </app-form-group-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.firstName' | translate}} *" name="firstName"
                                                id="firstName" placeHolder="{{'account.firstName' | translate}}"
                                                formControlNameValue="firstName" [formGroup]="clientForm"
                                                [error]="clientErrorArray['firstName']" required="true"
                                                ngDefaultControl></app-form-group-input>
                                        </div>
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.lastName' | translate}} *" name="lastName"
                                                id="lastName" formControlNameValue="lastName" [formGroup]="clientForm"
                                                placeHolder="{{'account.lastName' | translate}}"
                                                [error]="clientErrorArray['lastName']" required="true" ngDefaultControl>
                                            </app-form-group-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-form-group-input inputType="email"
                                                label="{{'account.email' | translate}} *" name="firstEmail"
                                                id="firstEmail" placeHolder="{{'account.email' | translate}}"
                                                required="true" formControlNameValue="firstEmail"
                                                [error]="clientErrorArray['firstEmail']" [formGroup]="clientForm"
                                                ngDefaultControl></app-form-group-input>
                                        </div>
                                        <div class="col">
                                            <app-form-group-input inputType="email"
                                                label="{{'account.secondEmail' | translate}}" name="secondEmail"
                                                id="secondEmail" placeHolder="{{'account.secondEmail' | translate}}"
                                                formControlNameValue="secondEmail"
                                                [error]="clientErrorArray['secondEmail']" [formGroup]="clientForm"
                                                ngDefaultControl></app-form-group-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.firstPhone' | translate}} *" name="firstPhone"
                                                id="firstPhone" placeHolder="{{'account.firstPhone' | translate}}"
                                                required="true" formControlNameValue="firstPhone"
                                                [error]="clientErrorArray['firstPhone']" [formGroup]="clientForm"
                                                ngDefaultControl></app-form-group-input>
                                        </div>
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.secondPhone' | translate}}" name="secondPhone"
                                                id="secondPhone" placeHolder="{{'account.secondPhone' | translate}}"
                                                formControlNameValue="secondPhone"
                                                [error]="clientErrorArray['secondPhone']" [formGroup]="clientForm"
                                                ngDefaultControl></app-form-group-input>
                                        </div>
                                    </div>
                                    <!--
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <app-form-group-select
                                                    label="{{'account.languageCorrespondence' | translate}}"
                                                    name="language" id="language" formControlNameValue="language"
                                                    [options]="languageOptions" [formGroup]="clientForm"
                                                    ngDefaultControl></app-form-group-select>
                                            </div>
                                        </div>
                                    </div>
                                    -->
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="newsletter"
                                                        id="newsletter" formControlName="newsletter"
                                                        [formGroup]="clientForm" ngDefaultControl>
                                                    <label class="form-check-label" for="newsletter">
                                                        Newsletter
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <small class="text-muted">{{'requiredField' | translate}}</small>
                                    <button type="submit"
                                        class="btn btn-info btn-fill float-right">{{'account.updateAccount' | translate}}</button>
                                </form>
                            </div>
                        </div>
                        <div class="card mt-3">
                            <div class="header">
                                <h4 class="title">{{'account.editPassword' | translate}}</h4>
                            </div>
                            <div class="content">
                                <div class="row">
                                    <div class="col">
                                        <app-simple-form-group-input inputType="password"
                                            label="{{'account.newPassword' | translate}} *" name="newPassword"
                                            id="newPassword" placeHolder="{{'account.newPassword' | translate}}"
                                            [(model)]="newPassword" [errorText]="newPasswordErrorMsg">
                                        </app-simple-form-group-input>
                                    </div>
                                    <div class="col">
                                        <app-simple-form-group-input inputType="password"
                                            label="{{'account.repeatPassword' | translate}} *" name="repeatPassword"
                                            id="repeatPassword" placeHolder="{{'account.repeatPassword' | translate}}"
                                            [(model)]="newPasswordRepeat" [errorText]="newPasswordRepeatErrorMsg">
                                        </app-simple-form-group-input>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <small class="text-muted">{{'requiredField' | translate}}</small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <button type="button" class="btn btn-info btn-fill float-right"
                                            (click)="updateClientPassword()">{{'account.updatePassword' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6">
                        <div class="card">
                            <div class="header">
                                <h4 class="title">{{'account.editCompany' | translate}}</h4>
                            </div>
                            <div class="content">
                                <form [formGroup]="companyForm" (ngSubmit)="onSubmitCompanyForm()"
                                    class="needs-validation">
                                    <div class="row">
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.companyName' | translate}} *" name="name" id="name"
                                                placeHolder="{{'account.companyName' | translate}}"
                                                formControlNameValue="name" [formGroup]="companyForm"
                                                [error]="companyErrorArray['name']" required="true" ngDefaultControl>
                                            </app-form-group-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-form-group-input inputType="email"
                                                label="{{'account.email' | translate}} *" name="companyFirstEmail"
                                                id="companyFirstEmail" placeHolder="{{'account.email' | translate}}"
                                                required="true" formControlNameValue="firstEmail"
                                                [error]="companyErrorArray['firstEmail']" [formGroup]="companyForm"
                                                ngDefaultControl></app-form-group-input>
                                        </div>
                                        <div class="col">
                                            <app-form-group-input inputType="email"
                                                label="{{'account.secondEmail' | translate}}" name="companySecondEmail"
                                                id="companySecondEmail"
                                                placeHolder="{{'account.secondEmail' | translate}}"
                                                formControlNameValue="secondEmail"
                                                [error]="companyErrorArray['secondEmail']" [formGroup]="companyForm"
                                                ngDefaultControl>
                                            </app-form-group-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.firstPhone' | translate}} *" name="companyFirstPhone"
                                                id="companyFirstPhone"
                                                placeHolder="{{'account.firstPhone' | translate}}" required="true"
                                                formControlNameValue="firstPhone"
                                                [error]="companyErrorArray['firstPhone']" [formGroup]="companyForm"
                                                ngDefaultControl></app-form-group-input>
                                        </div>
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.secondPhone' | translate}}" name="companySecondPhone"
                                                id="companySecondPhone"
                                                placeHolder="{{'account.secondPhone' | translate}}"
                                                formControlNameValue="secondPhone"
                                                [error]="companyErrorArray['secondPhone']" [formGroup]="companyForm"
                                                ngDefaultControl>
                                            </app-form-group-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-form-group-input inputType="text" label="{{'account.vat' | translate}}"
                                                name="vat" id="vat" placeHolder="{{'account.vat' | translate}}"
                                                formControlNameValue="vat" [error]="companyErrorArray['vat']"
                                                [formGroup]="companyForm" ngDefaultControl>
                                            </app-form-group-input>
                                        </div>
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.fiscalCode' | translate}}" name="fiscalCode"
                                                id="fiscalCode" placeHolder="{{'account.fiscalCode' | translate}}"
                                                formControlNameValue="fiscalCode"
                                                [error]="companyErrorArray['fiscalCode']" [formGroup]="companyForm"
                                                ngDefaultControl></app-form-group-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-form-group-input inputType="email"
                                                label="{{'account.pecEmail' | translate}}" name="pecEmail" id="pecEmail"
                                                placeHolder="{{'account.pecEmail' | translate}}"
                                                formControlNameValue="pecEmail" [error]="companyErrorArray['pecEmail']"
                                                [formGroup]="companyForm" ngDefaultControl></app-form-group-input>
                                        </div>
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.sdiNumber' | translate}}" name="isdNumber"
                                                id="isdNumber" placeHolder="{{'account.sdiNumber' | translate}}"
                                                formControlNameValue="isdNumber"
                                                [error]="companyErrorArray['isdNumber']" [formGroup]="companyForm"
                                                ngDefaultControl></app-form-group-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.address' | translate}} *" name="address" id="address"
                                                required="true" placeHolder="{{'account.address' | translate}}"
                                                formControlNameValue="address" [error]="companyErrorArray['address']"
                                                [formGroup]="companyForm" ngDefaultControl></app-form-group-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div [class]="locationGroupClass">
                                            <app-select-location-group [language]="currentLanguage"
                                                [villages]="villageObjects" [choosenVillageObject]="companyVillage"
                                                (onVillageChoosen)="choosenVillage = $event">
                                            </app-select-location-group>
                                            <div class="invalid" *ngIf="missingVillageErrorMsg">{{missingVillageErrorMsg}}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <app-form-group-input inputType="text"
                                                label="{{'account.postalCode' | translate}} *" name="postalCode"
                                                id="postalCode" required="true"
                                                placeHolder="{{'account.postalCode' | translate}}"
                                                formControlNameValue="postalCode"
                                                [error]="companyErrorArray['postalCode']" [formGroup]="companyForm"
                                                ngDefaultControl>
                                            </app-form-group-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <small class="text-muted">{{'requiredField' | translate}}</small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button type="submit"
                                                class="btn btn-info btn-fill float-right">{{'account.updateCompany' | translate}}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
