import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Village } from '../models/Village';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { Country } from '../models/Country';
import { Region } from '../models/Region';
import { Province } from '../models/Province';

@Component({
  selector: 'app-select-location-group',
  templateUrl: './select-location-group.component.html',
  styleUrls: ['./select-location-group.component.css']
})
export class SelectLocationGroupComponent implements OnInit, OnChanges {

  constructor() { }

  @Input()
  villages: Village[];

  @Input()
  language: string;

  @Input()
  choosenVillageObject: Village;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onVillageChoosen: EventEmitter<any> = new EventEmitter<any>();

  public villageOptions: Option[] = [];
  public countryOptions: Option[] = [];
  public regionOptions: Option[] = [];
  public provinceOptions: Option[] = [];
  public choosenProvince: Option;
  public choosenCountry: Option;
  public choosenRegion: Option;
  public choosenVillage: Option;

  private _countryObjectArray: Country[] = [];
  private _regionsObjectArray: Region[] = [];
  private _provinceObjectArray: Province[] = [];
  private _villagesObjectArray: Village[] = [];

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.countryOptions = [];
    this.regionOptions = [];
    this.provinceOptions = [];
    this.villageOptions = [];
    if (this.villages) {
      let villageObject: Village;
      if (this.choosenVillageObject) {
        villageObject = this.villages.filter(village => village.id === this.choosenVillageObject.id)[0];
      }
      this.villages.forEach(village => {
        const province = village.province;
        const region = province.region;
        const country = region.country;
        if (!this._isInObject(country.id, this._countryObjectArray)) {
          this._countryObjectArray.push(country);
        }
        if (!this._isInObject(region.id, this._regionsObjectArray)) {
          this._regionsObjectArray.push(region);
        }
        if (!this._isInObject(province.id, this._provinceObjectArray)) {
          this._provinceObjectArray.push(province);
        }
        if (!this._isInObject(village.id, this._villagesObjectArray)) {
          this._villagesObjectArray.push(village);
        }
      });
      this.choosenCountry = this._populateSelectOptions(this._countryObjectArray, this.countryOptions);
      this.countryOptions = [...this.countryOptions];
      if (this.choosenVillageObject) {
        this.choosenCountry = this.countryOptions.filter(country => country.value === villageObject.province.region.country.id)[0];
      }
      if (this.choosenCountry) {
        this.choosenRegion = this._populateSelectOptions(this._regionsObjectArray.filter(
          region => region.country.id === this.choosenCountry.value), this.regionOptions);
        if (this.choosenVillageObject) {
          this.choosenRegion = this.regionOptions.filter(region => region.value === villageObject.province.region.id)[0];
        }
        this.regionOptions = [...this.regionOptions];
      }
      if (this.choosenRegion) {
        this.choosenProvince = this._populateSelectOptions(
          this._provinceObjectArray.filter(province => province.region.id === this.choosenRegion.value), this.provinceOptions);
        if (this.choosenVillageObject) {
          this.choosenProvince = this.provinceOptions.filter(province => province.value === villageObject.province.id)[0];
        }
        this.provinceOptions = [...this.provinceOptions];
      }
      if (this.choosenProvince) {
        this.choosenVillage = this._populateSelectOptions(
          this._villagesObjectArray.filter(village => village.province.id === this.choosenProvince.value), this.villageOptions);
        if (this.choosenVillageObject) {
          this.choosenVillage = this.villageOptions.filter(village => village.value === villageObject.id)[0];
        }
        this.villageOptions = [...this.villageOptions].sort((a, b) => a.label.localeCompare(b.label));
      }
    }
  }

  onSelectChange(locationType: string) {
    switch (locationType) {
      case 'country':
        this.regionOptions = [];
        this.choosenRegion = undefined;
        this.provinceOptions = [];
        this.choosenProvince = undefined;
        this.villageOptions = [];
        this.choosenVillage = undefined;
        if (this.choosenCountry) {
          this._regionsObjectArray.map(region => {
            if (region.country.id === this.choosenCountry.value) {
              this.regionOptions.push({
                value: region.id,
                label: this._getCurrentLangLocationName(region.name, this.language)
              });
            }
          });
        }
        break;
      case 'region':
        this.provinceOptions = [];
        this.choosenProvince = undefined;
        this.villageOptions = [];
        this.choosenVillage = undefined;
        if (this.choosenRegion) {
          this._provinceObjectArray.map(province => {
            if (province.region.id === this.choosenRegion.value) {
              this.provinceOptions.push({
                value: province.id,
                label: this._getCurrentLangLocationName(province.name, this.language)
              });
            }
          });
        }
        break;
      case 'province':
        this.villageOptions = [];
        this.choosenVillage = undefined;
        if (this.choosenProvince) {
          this._villagesObjectArray.map(village => {
            if (village.province.id === this.choosenProvince.value) {
              this.villageOptions.push({
                value: village.id,
                label: this._getCurrentLangLocationName(village.name, this.language)
              });
            }
          });
        }
        this.villageOptions = [...this.villageOptions].sort((a, b) => a.label.localeCompare(b.label));
        break;
      default: // village
        this.onVillageChoosen.emit(this.choosenVillage);
        break;
    }
  }

  private _populateSelectOptions(objectArray, optionsArray, choosenId?): any {
    if (objectArray.length === 1) {
      optionsArray.push({
        value: objectArray[0].id,
        label: this._getCurrentLangLocationName(objectArray[0].name, this.language)
      });
      return optionsArray[0];
    } else {
      objectArray.map(obj => {
        optionsArray.push({
          value: obj.id,
          label: this._getCurrentLangLocationName(obj.name, this.language)
        });
      });
      return;
    }
  }

  private _isInObject(value: any, objectArray: any) {
    const foundValue = objectArray.filter(obj => obj.id === value)[0];
    return (!foundValue ? false : true);
  }

  private _getCurrentLangLocationName(locationName: string, language: string) {
    if (locationName.split('/').length === 1) {
      return locationName.split('/').pop();
    } else {
      if (language === 'de') {
        return locationName.split('/').pop();
      } else {
        return locationName.split('/').slice(0, -1).join('/');
      }
    }
  }

}
