import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class NavigationService {

  constructor(private router: Router) {
  }

  login(params?: NavigationExtras) {
    return new LoginNavigation(this.router).login(params);
  }

  home() {
    return new CoreNavigation(this.router).home();
  }

  register() {
    return new CoreNavigation(this.router).register();
  }

  registerConfirm() {
    return new CoreNavigation(this.router).registerConfirm();
  }

  priceListDashboard() {
    return new CoreNavigation(this.router).priceListDashboard();
  }

  editPriceList(priceListId?: number) {
    return new CoreNavigation(this.router).editPriceList(priceListId);
  }

  myEvents() {
    return new CoreNavigation(this.router).myEvents();
  }

  requestEvent() {
    return new CoreNavigation(this.router).requestEvent();
  }
}

class Navigation {

  path: string[] = [];
  params: NavigationExtras = {};
  router: Router;

  constructor(router: Router) {
    this.router = router;
  }
}

class Navigate extends Navigation implements Navigable {

  static fromNavigation(navigation: Navigation) {
    const navigate: Navigate = new Navigate(navigation.router);
    navigate.path = navigation.path;
    navigate.params = navigation.params;

    return navigate;
  }

  constructor(router: Router) {
    super(router);
  }

  go() {
    this.router.navigate(this.path, this.params)
      .catch(error => console.log(error));
  }

  getUrl(): string {
    const domain = window.location.origin;
    const path = this.path.join('/');
    return `${domain}/${path}`;
  }
}

class CoreNavigation extends Navigation {

  constructor(router: Router) {
    super(router);
  }

  home(): Navigable {
    this.path = ['dashboard'];
    return Navigate.fromNavigation(this);
  }

  register(): Navigable {
    this.path = ['register'];
    return Navigate.fromNavigation(this);
  }

  registerConfirm(): Navigable {
    this.path = ['register/confirm'];
    return Navigate.fromNavigation(this);
  }

  priceListDashboard(): Navigable {
    this.path = ['price-list/dashboard'];
    return Navigate.fromNavigation(this);
  }

  editPriceList(priceListId?: number): Navigable {
    this.path = ['price-list/edit/' + priceListId];
    return Navigate.fromNavigation(this);
  }

  myEvents(): Navigable {
    this.path = ['event/my-events'];
    return Navigate.fromNavigation(this);
  }

  requestEvent(): Navigable {
    this.path = ['request/event'];
    return Navigate.fromNavigation(this);
  }
}

class LoginNavigation extends Navigation {

  constructor(router: Router) {
    super(router);
  }

  login(params?: NavigationExtras): Navigable {
    this.path = ['login'];
    this.params = params;
    return Navigate.fromNavigation(this);
  }
}

interface Navigable {

  go();

  getUrl(): string;
}
