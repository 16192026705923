import { Printer } from './Printer';
import { PrinterManufacturer } from './PrinterManufacturers';

export class PrinterType {
    public id: number;
    public price: string;
    public name: string;
    public lan: boolean;
    public wifi: boolean;
    public usb: boolean;
    public rescureMode: boolean;
    public status: number;
    public printers: Printer[] = [];
    public printerManufacturer: PrinterManufacturer;

    static fromDto(p): PrinterType {
        const printerType = new PrinterType();

        if (!p) {
            return null;
        }

        printerType.id = p.id;
        printerType.price = p.price;
        printerType.name = p.name;
        printerType.lan = p.lan;
        printerType.wifi = p.wifi;
        printerType.usb = p.usb;
        printerType.rescureMode = p.rescureMode;
        printerType.status = p.status;
        printerType.printers = p.printers;
        printerType.printerManufacturer = p.printerManufacturers;

        return p;
    }
}
