import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChartModule } from 'angular-highcharts';
import { CoreModule } from '../core/core.module';
import { EVENT_ROUTES } from './event.routes';
import { SharedModule } from '../shared/shared.module';
import { EventComponent } from './event.component';
import { EventService } from './shared/event.service';
import { RequestComponent } from './request/request.component';
import { TimeRangeSelectComponent } from './shared/time-range-select/time-range-select.component';
import { MyEventsComponent } from './my-events/my-events.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EventStatusPipe } from './shared/pipes/event-status.pipe';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DeviceUsersComponent } from './event-details/device-users/device-users.component';
import { GeneralDetailsComponent } from './event-details/general-details/general-details.component';
import { PriceListEventComponent } from './event-details/price-list-event/price-list-event.component';
// tslint:disable-next-line:max-line-length
import { AssignPrinterProductCategoryComponent } from './event-details/assign-printer-product-category/assign-printer-product-category.component';
// tslint:disable-next-line:max-line-length
import { CustomSwitchPrinterComponent } from './event-details/assign-printer-product-category/custom-switch-printer/custom-switch-printer.component';
import { StatisticsComponent } from './event-details/statistics/statistics.component';
import { DeliveryPaperComponent } from './event-details/delivery-paper/delivery-paper.component';
import { DeviceTypeTranslationPipe } from './shared/pipes/device-type-translation.pipe';
import { EventLocationCardComponent } from './event-details/advertising-event/event-location-card/event-location-card.component';
import { AdvertisingEventComponent } from './event-details/advertising-event/advertising-event.component';
import { StatisticsKendoComponent } from './event-details/statistics-kendo/statistics-kendo.component';
import { CustomMessageService } from '../shared/custom-message.service';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule, PDFModule, ExcelModule} from '@progress/kendo-angular-grid';
import { MessageService } from '@progress/kendo-angular-l10n';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DialogsModule } from "@progress/kendo-angular-dialog";

@NgModule({
  imports: [
    RouterModule.forChild(EVENT_ROUTES),
    SharedModule,
    CoreModule,
    LeafletModule,
    ChartModule,
    IntlModule,
    DateInputsModule,
    LabelModule,
    InputsModule,
    GridModule,
    PDFModule,
    ExcelModule,
    ButtonsModule,
    LayoutModule,
    DropDownsModule,
    ChartsModule,
    DialogsModule
  ],
  declarations: [
    EventComponent,
    RequestComponent,
    TimeRangeSelectComponent,
    MyEventsComponent,
    EventDetailsComponent,
    EventStatusPipe,
    EventLocationCardComponent,
    DeviceUsersComponent,
    GeneralDetailsComponent,
    PriceListEventComponent,
    AssignPrinterProductCategoryComponent,
    CustomSwitchPrinterComponent,
    StatisticsComponent,
    DeliveryPaperComponent,
    DeviceTypeTranslationPipe,
    AdvertisingEventComponent,
    StatisticsKendoComponent,

  ],
  providers: [
    { provide: MessageService, useClass: CustomMessageService },
    EventService
  ]
})
export class EventModule { }
