import { Component, OnInit } from '@angular/core';
import { Language } from '../language';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

public languages = Language.getAvailableLanguages();
  public currentLanguage: any;

   constructor(private _translate: TranslateService,) {
    this.currentLanguage = Language.fromLocale(this._translate.currentLang) || Language.fromLocale(this._translate.getDefaultLang());
  }

  ngOnInit() {
  }

  changeLanguage(language: Language) {
    this.currentLanguage = language;
    this._translate.use(this.currentLanguage.locale);
    localStorage.setItem('locale', this.currentLanguage.locale);
  }
}
