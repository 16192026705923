import { Injectable } from '@angular/core';
import { LOCAL_STORAGE, URL_BASE } from './constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Client } from '../shared/models/Client';
import { HttpUtils } from 'src/app/shared/http-utils';
import { TranslateService } from '@ngx-translate/core';



@Injectable({ providedIn: 'root' })
export class AuthService {

  private accessToken: string;
  private _headers: any;

  constructor(private http: HttpClient, public jwtHelper: JwtHelperService, private _translate: TranslateService) {
    this.accessToken = this.getAccessToken();
    this._headers = HttpUtils.createHeaders();
  }
  /**
   * registerNewClientUser
   */
  public registerNewClientUser(client: Client): Observable<any> {
    return this.http.put(URL_BASE + '/clientUser/registration', { clientUser: client }).pipe(
      map((res: any) => res)
    );
  }

  public authenticate(username: string, password: string): Observable<boolean> {
    return this.http.post(URL_BASE + '/clientUser/login', { username, password }).pipe(
      map((res: any) => {
        if (res.token) {
          if (res.firstLogin === 1) {
            localStorage.setItem(LOCAL_STORAGE.FIRST_LOGIN, 'true');
            localStorage.setItem('locale', res.language);
            this._translate.use(res.language);
          }
          this.setAccessToken(res.token);
          return true;
        } else {
          return false;
        }
      }));
  }

  public authenticateFromAdmin(clientId: number): Observable<boolean> {
    console.log('authclient:', clientId);
    return this.http.get(URL_BASE + '/clientUser/loginFromAdmin', { params: { clientId } }).pipe(
      map((res: any) => {
        console.log('res',res);
        if (res.token) {
          localStorage.setItem(LOCAL_STORAGE.FIRST_LOGIN, 'false');
          localStorage.setItem('locale', res.language);
          this._translate.use(res.language);
          this.setAccessToken(res.token);
          return true;
        } else {
          return false;
        }
      }));
  }

  public getClientSpecificInformations(recoveryCode: string) {
    return this.http.get(URL_BASE + '/clientUser/getRecoveryCode', { params: { recoveryCode } }).pipe(
      map((res: any) => res)
    );
  }

  resetPasswordRequestClient(email: string) {
    return this.http.post(URL_BASE + '/rootUser/passwordRecoveryRequest', { email }).pipe(
      map((res: any) => {
        if (res.token) {
          this.setAccessToken(res.token);
          return true;
        } else {
          return false;
        }
      }));
  }

  resetClientPassword(recoveryCode: string, password: string) {
    return this.http.post(URL_BASE + '/clientUser/resetClientPassword',
      { recoveryCode: recoveryCode, password: password }).pipe(
        map((res: any) => {
          if (res) {
            return true;
          } else {
            return false;
          }
        }));
  }

  recoverClientPassword(email: string) {
    return this.http.post(URL_BASE + '/clientUser/recoverPassword', { email }).pipe(
      map((res: any) => {
        if (res.token) {
          this.setAccessToken(res.token);
          return true;
        } else {
          return false;
        }
      }));
  }

  resetPasswordRequest(email: string) {
    return this.http.get(`${URL_BASE}/admin/rootUser/passwordRecoveryRequest?rootUserEmail=${email}`, { responseType: 'text' }).pipe(
      map((res: any) => {
        if (res) {
          return true;
        } else {
          return false;
        }
      }));
  }

  public isAuthenticated(): boolean {
    const accessToken = this.getAccessToken();
    return !!accessToken && !this.jwtHelper.isTokenExpired();
  }

  public disconnect(): void {
    this.accessToken = null;
    for (const key of Object.keys(LOCAL_STORAGE)) {
      localStorage.removeItem(LOCAL_STORAGE[key]);
    }
  }

  private getAccessToken(): string {
    if (!this.accessToken) {
      this.accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    }
    return this.accessToken;
  }


  private setAccessToken(token: string) {
    this.accessToken = token;
    localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, token);
  }


}
